import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { changePassword } from "services/api/authApi/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const PasswordPage = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const emailParam = params.get("email");
    const tokenParam = params.get("token");

    if (emailParam) {
      setEmail(decodeURIComponent(emailParam));
    }

    if (tokenParam) {
      setToken(decodeURIComponent(tokenParam));
    }
  }, [location.search]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Email:", email);
    console.log("Token:", token);
    console.log("Şifre:", password);
    console.log("Şifre Tekrarı:", confirmPassword);
    changePasswordProcess(email, token, password, confirmPassword);
  };

  const changePasswordProcess = async (
    email,
    token,
    password,
    confirmPassword
  ) => {
    try {
      const changePasswordApi = await changePassword(
        email,
        token,
        password,
        confirmPassword
      );
      if (changePasswordApi.succes) {
        console.log(
          "PasswordPage -> changePasswordProcess -> Şifre Başarılı Şekilde Güncellendi",
          changePasswordApi.basicAciklama
        );
        toast.success(changePasswordApi.basicAciklama);
        navigate("/");
      } else {
        console.log(
          "PasswordPage -> changePasswordProcess -> Succes: false",
          changePasswordApi.basicAciklama
        );
        toast.error(changePasswordApi.basicAciklama);
      }
    } catch (error) {
      console.log("PasswordPage -> changePasswordProcess -> Error: ", error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-4 text-center">Şifre Değiştir</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Yeni Şifre
            </label>
            <input
              type="password"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Şifre Tekrarı
            </label>
            <input
              type="password"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className="px-4 py-2 bg-primary-brand-color text-white-color rounded-md"
            >
              Şifreyi Değiştir
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PasswordPage;
