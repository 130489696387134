import React, { useEffect } from "react";

import { IconButton } from "../generalComponents";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  pdf,
} from "@react-pdf/renderer";
import { IoMdClose } from "react-icons/io";
import ServisListItemPDF from "./ServisListItemPDF";
import RaporServisPdf from "./RaporServisPdf";

const RaporPopup = ({ onClose, servisList }) => {
  useEffect(() => {
    console.log("İlgili Servis", servisList[0]);
  }, []);

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-gray-color bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white-color p-8 rounded-lg shadow-lg">
        <div className="flex flex-row justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">
            Servis Kayıtlarını Raporlamak İstediğinize Emin misiniz ?{" "}
          </h2>

          {/* <IconButton
            onPress={onClose}
            bgColor={"red-color"}
            iconColor={"white-color"}
            Icon={IoMdClose}
          /> */}
        </div>
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="bg-red-color text-white-color px-4 py-2 rounded-md ml-4 mr-4"
          >
            İptal
          </button>
          <PDFDownloadLink
            document={<RaporServisPdf servisList={servisList} />}
            fileName={`ServisKayitlari.pdf`}
          >
            {({ loading }) => (
              <button
                onClick={() => {
                  console.log("Download ICON PRESSED", servisList);
                }}
                className="bg-green-color text-white-color px-4 py-2 rounded-md"
              >
                Raporu İndir
              </button>
            )}
          </PDFDownloadLink>
        </div>
      </div>
    </div>
  );
};

export default RaporPopup;
