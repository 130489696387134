import React, { useEffect, useState } from "react";
import {
  FaNewspaper,
  FaCrown,
  FaHammer,
  FaExpandArrowsAlt,
  FaPencilAlt,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function KullaniciListItem({ Kullanici, onUpdateClick }) {
  const navigate = useNavigate();
  const [icon, setIcon] = useState(null);

  const handleUpdateClick = (e) => {
    onUpdateClick(e, Kullanici); // Seçilen cari öğesini PopupUpdateCari bileşenine iletiyoruz.
  };

  useEffect(() => {
    if (Kullanici.Role === "Teknisyen") {
      setIcon(<FaHammer />);
    } else if (Kullanici.Role === "SuperAdmin" || Kullanici.Role === "Admin") {
      setIcon(<FaCrown />);
    } else {
      setIcon(<FaNewspaper />);
    }
  }, [Kullanici]);

  return (
    <div className="p-4 mb-4 rounded-md flex flex-row items-center justify-between border-2 border-primary-brand-color cursor-pointer">
      <div className="flex flex-row items-center">
        <div className=" bg-primary-brand-color mr-2 p-3  flex justify-center items-center text-white-color rounded-full">
          {icon}
        </div>
        <div className="flex flex-row items-center">
          <div className="flex flex-col">
            <h2 className="text-xl font-serif font-semibold ">
              {`${Kullanici.NameSurname}`}
            </h2>
            <h3 className=" text-base">{`Rol: ${Kullanici.Role}`}</h3>
          </div>
        </div>
      </div>
      <div>
        <button
          onClick={(e) => {
            handleUpdateClick(e);
          }}
          className="  text-primary-brand-color px-2 py-2 border-2 border-primary-brand-color rounded-full text-lg mr-4"
        >
          <FaPencilAlt />
        </button>
      </div>
    </div>
  );
}

export default KullaniciListItem;
