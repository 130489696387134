import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa"; // react-icons'dan FaTimes ikonunu içe aktarın
import { addParcaSchema } from "services/helpers/validations";
import { useFormik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addParca } from "services/api/yonetimApi/api";

const AddParcaPopup = ({
  isOpen,
  onClose,
  buttonPosition,
  options,
  defaultValue,
}) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [selectedCariId, setSelectedCariId] = useState(null);

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    useFormik({
      initialValues: {
        ParcaAdi: "",
        ParcaTuru: "",
      },
      onSubmit: async (values, bag) => {
        bag.resetForm();

        const parcaAdiSubmit = values.ParcaAdi;
        const parcaTuruSubmit = values.ParcaTuru;
        addParcaProcess(parcaAdiSubmit, parcaTuruSubmit);
      },
      validationSchema: addParcaSchema,
    });

  useEffect(() => {
    //getCariler();
    //console.log(options);
  }, []);

  useEffect(() => {
    if (Object.keys(touched).length > 0 && Object.keys(errors).length === 0) {
      setIsFormSubmitted(true);
    } else {
      setIsFormSubmitted(false);
    }
  }, [touched, errors]);

  const addParcaProcess = async (parcaAdi, parcaTuru) => {
    try {
      const addParcaApi = await addParca(parcaAdi, parcaTuru);
      if (addParcaApi.succes) {
        console.log(
          "AddParcaPopup -> AddParcaProcess -> Parca eklendi",
          addParcaApi.basicAciklama
        );
        toast.success(addParcaApi.basicAciklama);
        onClose();
      } else {
        console.log(
          "AddParcaPopup -> AddParcaProcess -> Parca Eklerken bir hata oluştu",
          addParcaApi.basicAciklama
        );
        toast.error(addParcaApi.basicAciklama);
      }
    } catch (error) {
      console.log("AddParcaPopup -> addParcaProcess -> Error: ", error);
    }
  };

  return (
    isOpen && (
      <div className="fixed inset-0 bg-black-color bg-opacity-50 flex justify-center items-center">
        <div className="bg-white-color p-8 rounded-lg w-2/4 h-3/6 border-2 border-primary-brand-color overflow-auto">
          <div className="flex justify-between">
            <h3 className="text-primary-brand-color font-serif font-bold text-lg">
              Model Ekle
            </h3>
            <button onClick={onClose} className="focus:outline-none">
              <FaTimes className="text-primary-brand-color" />{" "}
              {/* Kapatma butonu için FaTimes ikonunu kullanın */}
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1"
                htmlFor="ParcaAdi"
              >
                Parça Adı
              </label>
              <input
                type="text"
                id="ParcaAdi"
                name="ParcaAdi"
                value={values.ParcaAdi}
                onChange={handleChange}
                onBlur={handleBlur}
                className="w-full border-2 border-gray-200 p-1 rounded-md"
              />
              {errors.ParcaAdi && touched.ParcaAdi && (
                <div>
                  <p className="font-semibold text-xs text-red-color">
                    {errors.ParcaAdi}
                  </p>
                </div>
              )}
            </div>

            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1"
                htmlFor="ParcaTuru"
              >
                Parça Tür
              </label>
              <input
                type="text"
                id="ParcaTuru"
                name="ParcaTuru"
                value={values.ParcaTuru}
                onChange={handleChange}
                onBlur={handleBlur}
                className="w-full border-2 border-gray-200 p-1 rounded-md"
              />
              {errors.ParcaTuru && touched.ParcaTuru && (
                <div>
                  <p className="font-semibold text-xs text-red-color">
                    {errors.ParcaTuru}
                  </p>
                </div>
              )}
            </div>

            <button
              type="submit"
              className={`bg-green-color text-white-color px-4 py-2 rounded-md ${
                isFormSubmitted ? "" : "bg-opacity-50 cursor-not-allowed"
              }`}
              disabled={!isFormSubmitted}
            >
              Kaydet
            </button>
          </form>
        </div>
      </div>
    )
  );
};

export default AddParcaPopup;
