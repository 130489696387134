import React from "react";
import { IoMdAdd } from "react-icons/io";
import { IoFilterSharp } from "react-icons/io5";

function Header({ onAddClick, title, addTitle, isFilter = true }) {
  return (
    <div className=" w-full flex justify-between items-center bg-white-color p-3 border-b-2 border-primary-brand-color">
      <h1 className=" w-9/12 text-primary-brand-color text-2xl font-semibold font-serif">
        {title}
      </h1>
      <div className=" w-3/12 flex flex-row   py-2 justify-end">
        {isFilter && (
          <button className=" bg-secondary-brand-color text-white-color px-2 py-2 border-2 border-primary-brand-color rounded-full text-lg mr-4">
            <IoFilterSharp />
          </button>
        )}

        <button
          className=" bg-secondary-brand-color flex  flex-row justify-center items-center text-white-color px-2 py-2 border-2 border-primary-brand-color  rounded-full text-xl"
          onClick={onAddClick}
        >
          <p className=" text-lg">{addTitle}</p>
          <IoMdAdd size={22} />
        </button>
      </div>
    </div>
  );
}

export default Header;
