import React, { useEffect, useState } from "react";
import { Header } from "../generalComponents";
import AddModelPopup from "./AddModelPopup";
import { getMarkalar, getModeller } from "services/api/yonetimApi/api";
//import MarkaSelect from "./MarkaSelect";
import MarkaSelect from "../deviceComponents/MarkaSelect";
import TableItemModel from "./TabItemModel";
import UpdateModelPopup from "./UpdateModelPopup";

function AddModelView() {
  const [buttonPosition, setButtonPosition] = useState({ x: 0, y: 0 });
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [markaList, setMarkaList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [selectedMarkaId, setSelectedMarkaId] = useState(null);
  const [selectedMarka, setSelectedMarka] = useState(null);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [currentModel, setCurrentModel] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageNumber, setTotalPageNumber] = useState(1);

  useEffect(() => {
    getMarkalarProcess();
    console.log("deneme");
    //getModellerProcess();
  }, []);

  useEffect(() => {
    //getMarkalarProcess();
    getModellerProcess(selectedMarkaId);
  }, [pageNumber]);

  const plusPageProcess = async () => {
    if (pageNumber < totalPageNumber) {
      setPageNumber(pageNumber + 1);
    }
  };

  const minusPageProcess = async () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleAddClick = (e) => {
    // Butonun konumunu alın
    const buttonRect = e.target.getBoundingClientRect();
    setButtonPosition({ x: buttonRect.left, y: buttonRect.bottom });

    // Popup'u açın
    setIsPopupOpen(true);
  };

  const handleClose = () => {
    // Popup'u kapatın
    setIsPopupOpen(false);
  };

  const handleUpdateClose = () => {
    setShowUpdatePopup(false);
  };

  const toggleUpdatePopup = (e, model) => {
    const buttonRect = e.target.getBoundingClientRect();
    setButtonPosition({ x: buttonRect.right, y: buttonRect.top });

    setCurrentModel(model); // Seçilen cari bilgisini state'e kaydediyoruz
    setShowUpdatePopup(!showUpdatePopup);
  };

  const getMarkalarProcess = async () => {
    try {
      const getMarkalarApi = await getMarkalar(1, 1000);
      if (getMarkalarApi.succes) {
        // console.log(
        //   "Markalar Başarılı Bir Şekilde Çekildi"
        // );
        setMarkaList(getMarkalarApi.data.data);
      } else {
        console.log(
          "AddModelView -> getMarkalarProcess -> Markaları çekerken bir hata oluştu"
        );
      }
    } catch (error) {
      console.log("AddModelView -> getMarkalarProcess -> Error: ", error);
    }
  };

  const getModellerProcess = async (markaId) => {
    console.log(markaId);
    try {
      const getModellerApi = await getModeller(markaId, pageNumber, 10);
      if (getModellerApi.succes) {
        console.log(
          "addModelView -> getModellerProcess -> modeller basarılı bir sekilde cekildi"
        );
        setModelList(getModellerApi.data.data);
        setTotalPageNumber(getModellerApi.data.TotalPage);
        console.log(getModellerApi.data);
      } else {
        console.log(
          "addModelView -> getModellerProcess -> Modeller cekilirken bir hata oluştu"
        );
      }
    } catch (error) {
      console.log("AddModelView -> getModellerProcess -> Error: ", error);
    }
  };

  const handleMarkaSelect = (marka) => {
    getModellerProcess(marka.value);
    setSelectedMarkaId(marka.value);
    setSelectedMarka(marka);
  };

  return (
    <div className="w-full h-full">
      <Header
        title={"Model Listesi"}
        onAddClick={handleAddClick}
        pageNumber={pageNumber}
        totalPageNumber={totalPageNumber}
        plusPage={plusPageProcess}
        minusPage={minusPageProcess}
        isFilter={false}
        addTitle={"Model Ekle"}
      />

      {markaList.length != 0 && (
        <MarkaSelect
          options={markaList}
          onChange={handleMarkaSelect}
          addOperation={false}
        />
      )}

      <AddModelPopup
        isOpen={isPopupOpen}
        onClose={handleClose}
        buttonPosition={buttonPosition}
        options={markaList}
        defaultValue={selectedMarka}
      />

      <div className="mt-4">
        {modelList.map((model) => (
          <TableItemModel
            key={model.ModelId}
            model={model}
            onUpdateClick={(e, model) => {
              toggleUpdatePopup(e, model);
            }}
          />
        ))}
      </div>
      {showUpdatePopup && (
        <div className="fixed inset-0 bg-black-color bg-opacity-50 flex justify-center items-center">
          <UpdateModelPopup
            isOpen={showUpdatePopup}
            onClose={handleUpdateClose}
            currentModel={currentModel}
            buttonPosition={buttonPosition}
          />
        </div>
      )}
    </div>
  );
}

export default AddModelView;
