import React, { useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { getSehirler, getIlceler } from "services/api/yonetimApi/api";
import { SehirSelect, IlceSelect, CariTipSelect } from "../generalComponents";
import { aktifDurumData, musteriTipData, cariStatusData } from "data/data";
import DurumSelect from "../generalComponents/DurumSelect";
import StatusSelect from "../generalComponents/StatusSelect";

function PopupFilterCari({ onClose, onFilter, searchTerm }) {
  const [sehirlerList, setSehirlerList] = useState([]);
  const [ilcelerList, setIlcelerList] = useState([]);
  const [selectedSehirId, setSelectedSehirId] = useState(null);
  const [selectedIlceId, setSelectedIlceId] = useState(null);
  const [selectedDurum, setSelectedDurum] = useState(null);
  const [selectedMusteriTip, setSelectedMusteriTip] = useState(null);
  const [selectedCariStatus, setSelectedCariStatus] = useState(null);

  useEffect(() => {
    getSehirlerProcess();
  }, []);

  const getSehirlerProcess = async () => {
    try {
      const getSehirlerApi = await getSehirler();
      setSehirlerList(getSehirlerApi.data);
    } catch (error) {
      console.log("PopupAddCari -> getSehirlerProcess -> Error: ", error);
    }
  };

  const getIlceProcess = async (sehirId) => {
    try {
      const getIlcelerApi = await getIlceler(sehirId);
      setIlcelerList(getIlcelerApi.data);
    } catch (error) {
      console.log("PopupAddCari -> getIlcelerProcess -> Error: ", error);
    }
  };

  const handleSehirChange = (sehir) => {
    setSelectedSehirId(sehir.value);
    getIlceProcess(sehir.value);
  };

  const handleIlceChange = (ilce) => {
    setSelectedIlceId(ilce.value);
  };

  const handleCariDurumChange = (durum) => {
    setSelectedDurum(durum.value);
  };

  const handleCariStatusChange = (status) => {
    setSelectedCariStatus(status.value);
  };

  const handleMusteriTipChange = (musteri) => {
    setSelectedMusteriTip(musteri.value);
  };

  const handleFilter = () => {
    if (selectedMusteriTip != null) {
      selectedMusteriTip = [selectedMusteriTip];
    }
    onFilter({
      sehirId: selectedSehirId,
      ilceId: selectedIlceId,
      durum: selectedDurum,
      musteriTip: selectedMusteriTip,
      cariStatus: selectedCariStatus,
      search: searchTerm,
    });
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black-color bg-opacity-50">
      <div className="bg-white-color p-8 rounded-lg w-2/4 h-5/6 border-2 border-primary-brand-color overflow-auto">
        <div className="flex flex-row justify-between items-center mb-3">
          <h2 className="text-2xl font-semibold">Cari Filtrele</h2>
          <button
            className=" text-xl text-primary-brand-color"
            onClick={onClose}
          >
            <IoMdClose />
          </button>
        </div>
        <hr />
        <div className="my-4">
          <label className="block text-sm font-semibold mb-1" htmlFor="Şehir">
            Şehir
          </label>
          <SehirSelect
            options={sehirlerList}
            onChange={handleSehirChange}
            addOperation={true}
            defaultValue={selectedSehirId}
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-semibold mb-1" htmlFor="İlce">
            İlçe
          </label>
          <IlceSelect
            options={ilcelerList}
            onChange={handleIlceChange}
            addOperation={true}
            defaultValue={selectedIlceId}
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-semibold mb-1" htmlFor="İlce">
            Cari Durum
          </label>
          <DurumSelect
            options={aktifDurumData}
            onChange={handleCariDurumChange}
            defaultValue={selectedDurum}
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-sm font-semibold mb-1"
            htmlFor="MusteriTip"
          >
            Müşteri Tür
          </label>
          <CariTipSelect
            options={musteriTipData}
            onChange={handleMusteriTipChange}
            defaultValue={selectedMusteriTip}
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-sm font-semibold mb-1"
            htmlFor="MusteriTip"
          >
            Cari Status
          </label>
          <StatusSelect
            options={cariStatusData}
            onChange={handleCariStatusChange}
            defaultValue={selectedCariStatus}
          />
        </div>
        <div className="flex w-full justify-end">
          <button
            type="button"
            className={`bg-primary-brand-color text-white-color px-4 py-2 rounded-md`}
            onClick={handleFilter}
          >
            Filtrele
          </button>
        </div>
      </div>
    </div>
  );
}

export default PopupFilterCari;
