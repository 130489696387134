import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa"; // react-icons'dan FaTimes ikonunu içe aktarın
import { addMarkaSchema, addModelSchema } from "services/helpers/validations";
import { useFormik } from "formik";
//import MarkaSelect from "./MarkaSelect";
import MarkaSelect from "../deviceComponents/MarkaSelect";
import { addModel } from "services/api/yonetimApi/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddModelPopup = ({
  isOpen,
  onClose,
  buttonPosition,
  options,
  defaultValue,
  selectedMarka,
}) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [selectedCariId, setSelectedCariId] = useState(null);
  const [defaultMarka, setDefaultMarka] = useState(null);

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    useFormik({
      initialValues: {
        ModelAdi: "",
      },
      onSubmit: async (values, bag) => {
        bag.resetForm();
        const modelAdiSubmit = values.ModelAdi;
        addModelProcess(modelAdiSubmit);
      },
      validationSchema: addModelSchema,
    });

  useEffect(() => {
    //getCariler();
    console.log("-----> ", defaultValue);
    console.log("----->", selectedMarka);
    //setDefaultMarka(formatMarka(defaultValue));
    //console.log(formatMarka(defaultValue));
    if (selectedMarka != undefined) {
      const selectedDefaultMarka = formatMarka(selectedMarka);
      console.log(selectedDefaultMarka);
      setDefaultMarka(selectedDefaultMarka);
    }
  }, []);

  const formatMarka = (marka) => {
    return {
      MarkaId: marka.value,
      MarkaAdi: marka.label,
    };
  };

  useEffect(() => {
    if (Object.keys(touched).length > 0 && Object.keys(errors).length === 0) {
      setIsFormSubmitted(true);
    } else {
      setIsFormSubmitted(false);
    }
  }, [touched, errors]);

  const addModelProcess = async (modelAdi) => {
    try {
      const addModelApi = await addModel(selectedCariId, modelAdi);
      if (addModelApi.succes) {
        console.log(
          "AddModelPopup -> addModelProcess -> Model başarılı eklendi",
          addModelApi.basicAciklama
        );
        toast.success(addModelApi.basicAciklama);
        onClose();
      } else {
        console.log(
          "AddModelPopup -> addModelProcess -> Model Eklenirken Bir Hata Oluştu",
          addModelApi.basicAciklama
        );
        toast.error(addModelApi.basicAciklama);
      }
    } catch (error) {
      console.log("AddModelPopup -> addModelProcess -> Error: ", error);
    }
  };

  const handleMarkaChange = (marka) => {
    console.log(marka);
    setSelectedCariId(marka.value);
  };

  return (
    isOpen && (
      <div className="fixed inset-0 bg-black-color bg-opacity-50 flex justify-center items-center">
        <div
          className="bg-white-color p-8 rounded-lg w-2/4 h-3/6 border-2 border-primary-brand-color overflow-auto"
          //style={{ top: buttonPosition.y + 15, left: buttonPosition.x - 200 }}
        >
          <div className="flex justify-between">
            <h3 className="text-primary-brand-color font-serif font-bold text-lg">
              Model Ekle
            </h3>
            <button onClick={onClose} className="focus:outline-none">
              <FaTimes className="text-primary-brand-color" />{" "}
              {/* Kapatma butonu için FaTimes ikonunu kullanın */}
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1"
                htmlFor="MarkaAdi"
              >
                Model Adı
              </label>
              <input
                type="text"
                id="ModelAdi"
                name="ModelAdi"
                value={values.ModelAdi}
                onChange={handleChange}
                onBlur={handleBlur}
                className="w-full border-2 border-gray-200 p-1 rounded-md"
              />
              {errors.ModelAdi && touched.ModelAdi && (
                <div>
                  <p className="font-semibold text-xs text-red-color">
                    {errors.ModelAdi}
                  </p>
                </div>
              )}
            </div>

            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1"
                htmlFor="Cari"
              >
                Marka Adı
              </label>
              {options.length != 0 && (
                <MarkaSelect
                  options={options}
                  defaultValue={selectedMarka ? formatMarka(selectedMarka) : ""}
                  onChange={handleMarkaChange}
                  addOperation={false}
                />
              )}
            </div>
            <button
              type="submit"
              className={`bg-green-color text-white-color px-4 py-2 rounded-md ${
                isFormSubmitted ? "" : "bg-opacity-50 cursor-not-allowed"
              }`}
              disabled={!isFormSubmitted}
            >
              Kaydet
            </button>
          </form>
        </div>
      </div>
    )
  );
};

export default AddModelPopup;
