import React, { useEffect } from "react";
import { IconButton } from "../generalComponents";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  pdf,
} from "@react-pdf/renderer";
import { IoMdClose } from "react-icons/io";
import ServisListItemPDF from "./ServisListItemPDF";

const PdfPopup = ({ onClose, servis }) => {
  useEffect(() => {
    console.log("İlgili Servis", servis);
  }, []);

  const handlePrint = () => {
    // Yazdırma işlemini başlat
    const handlePrint = async () => {
      const pdfContent = <ServisListItemPDF servis={servis} />;

      // PDF içeriğini blob olarak oluştur
      let myBlob = await pdf(pdfContent).toBlob();
      console.log(myBlob);
      //   const blob = new Blob([myBlob], { type: "application/pdf" });
      const url = URL.createObjectURL(myBlob);

      // iframe oluştur
      const iframe = document.createElement("iframe");
      iframe.style.position = "absolute";
      iframe.style.opacity = "0";
      iframe.style.width = "0";
      iframe.style.height = "0";
      iframe.src = url;

      // iframe'i sayfaya ekle ve yazdırma işlemini başlat
      document.body.appendChild(iframe);
      iframe.contentWindow.print();
    };

    handlePrint();
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-gray-color bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white-color p-8 rounded-lg shadow-lg">
        <div className="flex flex-row justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">Servis Rapor İşlemleri</h2>

          <IconButton
            onPress={onClose}
            bgColor={"red-color"}
            iconColor={"white-color"}
            Icon={IoMdClose}
          />
        </div>
        <div className="flex justify-between">
          <PDFDownloadLink
            document={<ServisListItemPDF servis={servis} />}
            fileName={`Servis No:${servis.ServisId}.pdf`}
          >
            {({ loading }) => (
              <button
                onClick={() => {
                  console.log("Download ICON PRESSED", servis);
                }}
                className="bg-green-color text-white-color px-4 py-2 rounded-md"
              >
                Raporu İndir
              </button>
            )}
          </PDFDownloadLink>
          <button
            onClick={handlePrint}
            className="bg-primary-brand-color text-white-color px-4 py-2 rounded-md ml-4"
          >
            Raporu Yazdır
          </button>
        </div>
      </div>
    </div>
  );
};

export default PdfPopup;
