import React from "react";

const MesajModalWithIcon = ({ Icon, message }) => {
  return (
    <div className="flex flex-col items-center justify-center h-full w-full">
      <div className="mb-4 text-primary-brand-color">
        <Icon size={64} />
      </div>
      <p className="text-lg font-semibold text-gray-700">{message}</p>
    </div>
  );
};

export default MesajModalWithIcon;
