import axios from "axios";
import {
  ADD_KULLANİCİ,
  GET_ALL_KULLANİCİ,
  GET_KULLANİCİ_YETKI_BY_ID,
  UPDATE_KULLANİCİ,
} from "services/helpers/contants";
import axiosApiInstance from "../axiosClient";

export const getKullanici = async (kullaniciTuru) => {
  try {
    if (kullaniciTuru == null || kullaniciTuru == undefined) {
      const { data } = await axiosApiInstance.get(`${GET_ALL_KULLANİCİ}`);
      if (data.BasicStatus === "Basarili") {
        return {
          succes: true,
          basicAciklama: data.BasicAciklama,
          data: data.data,
        };
      } else {
        console.log(
          "kullaniciApi -> api -> getKullanici -> Veri Çekilirken Hata: ",
          data.BasicAciklama
        );
        return {
          succes: false,
          basicAciklama: data.BasicAciklama,
        };
      }
    } else {
      const { data } = await axiosApiInstance.get(
        `${GET_ALL_KULLANİCİ}?role=${kullaniciTuru}`
      );
      if (data.BasicStatus === "Basarili") {
        return {
          succes: true,
          basicAciklama: data.BasicAciklama,
          data: data.data,
        };
      } else {
        console.log(
          "kullaniciApi -> api -> getKullanici -> Veri Çekilirken Hata: ",
          data.BasicAciklama
        );
        return {
          succes: false,
          basicAciklama: data.BasicAciklama,
        };
      }
    }
  } catch (err) {
    console.log("KullaniciApi -> api -> getKullanici -> Error:", err);
  }
};

export const addKullanici = async (
  adSoyad,
  email,
  sifre,
  sifreTekrar,
  role
) => {
  try {
    const { data } = await axiosApiInstance.post(`${ADD_KULLANİCİ}`, {
      AdSoyad: adSoyad,
      Email: email,
      Sifre: sifre,
      SifreTekrar: sifreTekrar,
      Role: role,
    });
    if (data.BasicStatus === "Basarili") {
      console.log(
        "KullaniciApi -> api -> addKullanici -> Kullanici Başarılı Şekilde Eklendi ",
        data.BasicAciklama
      );
      return {
        succes: true,
        basicAciklama: data.BasicAciklama,
      };
    } else {
      console.log(
        "KullaniciApi -> api -> addKullanici -> Kullanici Eklerken Hata: ",
        data.BasicAciklama
      );
    }
  } catch (err) {
    console.log("KullaniciApi -> api -> addKullanici -> Error:", err);
  }
};

export const getKullaniciYetkiById = async (kullaniciId) => {
  try {
    const { data } = await axiosApiInstance.get(
      `${GET_KULLANİCİ_YETKI_BY_ID}?id=${kullaniciId}`
    );
    if (data.BasicStatus === "Basarili") {
      console.log(
        "KullaniciApi -> api -> addKullanici -> Kullanici Başarılı Şekilde Eklendi ",
        data.BasicAciklama
      );
      return {
        succes: true,
        basicAciklama: data.BasicAciklama,
        data: data.data,
      };
    } else {
      console.log(
        "KullaniciApi -> api -> getKullaniciYetki -> Kullanici Yetkileri Çekilirken Hata: ",
        data.BasicAciklama
      );
      return {
        succes: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("KullaniciApi -> api -> getKullaniciYetkiById -> Error:", err);
  }
};

export const updateKullanici = async (
  kullaniciId,
  nameSurname,
  role,
  yetkiList
) => {
  try {
    const { data } = await axiosApiInstance.post(`${UPDATE_KULLANİCİ}`, {
      KullaniciId: kullaniciId,
      NameSurname: nameSurname,
      Role: role,
      Yetkiler: yetkiList,
    });
    if (data.BasicStatus === "Basarili") {
      console.log(
        "KullaniciApi -> api -> updateKullanici -> Kullanici Başarılı Şekilde GÜncellendi ",
        data.BasicAciklama
      );
      return {
        succes: true,
        basicAciklama: data.BasicAciklama,
      };
    } else {
      console.log(
        "KullaniciApi -> api -> updateKullanici -> Kullanici Güncellenirken Hata: ",
        data.BasicAciklama
      );
      return {
        succes: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("KullaniciApi -> api -> updateKullanici -> Error: ", err);
  }
};
