import React, { useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { useFormik } from "formik";
import { addKullaniciSchema } from "services/helpers/validations";
import { kullaniciTipData } from "data/data";
import KullaniciTipSelect from "./KullaniciTipSelect";
import {
  addKullanici,
  getKullaniciYetkiById,
  updateKullanici,
} from "services/api/kullaniciApi/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function UpdateKullaniciPopup({ onClose, currentKullanici }) {
  const [isFormSubmitted, setIsFormSubmitted] = useState(true);
  const [selectedRole, setSelectedRole] = useState(currentKullanici.Role);
  const [yetkiList, setYetkiList] = useState([]);

  const handleRoleTipChange = (role) => {
    setSelectedRole(role.value);
  };

  useEffect(() => {
    getKullaniciYetkiProcess();
  }, []);

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    useFormik({
      initialValues: {
        AdSoyad: currentKullanici.NameSurname,
        //Soyad: "",
        Email: "Güncellenemez@gmail.com",
        Sifre: "Güncellenemez",
        SifreTekrar: "Güncellenemez",
      },

      onSubmit: async (values, bag) => {
        bag.resetForm();
        const AdSoyadSubmit = values.AdSoyad;

        console.log(AdSoyadSubmit, selectedRole, yetkiList);

        updateKullaniciProcess(AdSoyadSubmit);
      },
      validationSchema: addKullaniciSchema,
    });

  //   useEffect(() => {
  //     if (Object.keys(touched).length > 0 && Object.keys(errors).length === 0) {
  //       setIsFormSubmitted(true);
  //     } else {
  //       setIsFormSubmitted(false);
  //     }
  //   }, [touched, errors]);

  const updateKullaniciProcess = async (AdSoyadSubmit) => {
    try {
      const updateKullaniciApi = await updateKullanici(
        currentKullanici.KullaniciId,
        AdSoyadSubmit,
        selectedRole,
        yetkiList
      );
      if (updateKullaniciApi.succes) {
        console.log(
          "updateKullaniciPopup -> updateKullaniciProcess -> Kullanici Başarılı Şekilde Güncellendi ",
          updateKullaniciApi.basicAciklama
        );
        toast.success(updateKullaniciApi.basicAciklama);
        onClose();
      } else {
        console.log(
          "updateKullaniciPopup -> updateKullaniciProcess -> Kullanici Güncellenirken Hata: ",
          updateKullaniciApi.basicAciklama
        );
        toast.error(updateKullaniciApi.basicAciklama);
        onClose();
      }
    } catch (err) {
      console.log(
        "updateKullaniciPopup -> updateKullaniciProcess -> Err: ",
        err
      );
    }
  };

  const getKullaniciYetkiProcess = async () => {
    try {
      const getKullaniciYetkiApi = await getKullaniciYetkiById(
        currentKullanici.KullaniciId
      );
      if (getKullaniciYetkiApi.succes) {
        console.log(
          "UpdateKullaniciPopup -> getKullaniciYetkiProcess -> Yetkiler Başarılı Listelendi:",
          getKullaniciYetkiApi.basicAciklama
        );
        setYetkiList(getKullaniciYetkiApi.data);
      } else {
        console.log(
          "UpdateKullaniciPopup -> getKullaniciYetkiProcess -> Yetkiler Listelenirken Hata:",
          getKullaniciYetkiApi.basicAciklama
        );
      }
    } catch (error) {
      console.log(
        "UpdateKullaniciPopup -> getKullaniciYetkiProcess -> Error: ",
        error
      );
    }
  };

  const handleYetkiChange = (index, value) => {
    const updatedYetkiler = [...yetkiList];
    updatedYetkiler[index].Deger = value ? "true" : "false";
    setYetkiList(updatedYetkiler);
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black-color bg-opacity-50">
      <div className="bg-white-color p-8 rounded-lg w-3/4 h-5/6 border-2 border-primary-brand-color overflow-auto">
        <div className="flex flex-row justify-between items-center mb-3">
          <h2 className="text-2xl font-semibold">Kullanıcı Güncelle</h2>
          <button
            className=" text-xl text-primary-brand-color"
            onClick={onClose}
          >
            <IoMdClose />
          </button>
        </div>
        <hr />
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-2 mt-4">
            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1"
                htmlFor="AdSoyad"
              >
                Kullanıcı Ad-Soyad
              </label>
              <input
                type="text"
                id="AdSoyad"
                name="AdSoyad"
                value={values.AdSoyad}
                onChange={handleChange}
                onBlur={handleBlur}
                className="w-full border-2 border-gray-200 p-1 rounded-md"
              />
              {errors.AdSoyad && touched.AdSoyad && (
                <div>
                  <p className="font-semibold text-xs text-red-color">
                    {errors.AdSoyad}
                  </p>
                </div>
              )}
            </div>

            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1"
                htmlFor="Email"
              >
                Kullanıcı Email
              </label>
              <input
                type="text"
                id="Email"
                name="Email"
                value={values.Email}
                onChange={handleChange}
                onBlur={handleBlur}
                className="w-full border-2 border-gray-200 p-1 rounded-md"
                disabled={true}
              />
              {errors.Email && touched.Email && (
                <div>
                  <p className="font-semibold text-xs text-red-color">
                    {errors.Email}
                  </p>
                </div>
              )}
            </div>

            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1"
                htmlFor="Sifre"
              >
                Kullanıcı Şifre
              </label>
              <input
                type="text"
                id="Sifre"
                name="Sifre"
                value={values.Sifre}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={true}
                className="w-full border-2 border-gray-200 p-1 rounded-md"
              />
              {errors.Sifre && touched.Sifre && (
                <div>
                  <p className="font-semibold text-xs text-red-color">
                    {errors.Sifre}
                  </p>
                </div>
              )}
            </div>

            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1"
                htmlFor="SifreTekrar"
              >
                Kullanıcı Şifre Tekrar
              </label>
              <input
                type="text"
                id="SifreTekrar"
                name="SifreTekrar"
                value={values.SifreTekrar}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={true}
                className="w-full border-2 border-gray-200 p-1 rounded-md"
              />
              {errors.SifreTekrar && touched.SifreTekrar && (
                <div>
                  <p className="font-semibold text-xs text-red-color">
                    {errors.SifreTekrar}
                  </p>
                </div>
              )}
            </div>

            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1 z-50"
                htmlFor="Role"
              >
                Kullanıcı Rol
              </label>
              <KullaniciTipSelect
                options={kullaniciTipData}
                onChange={handleRoleTipChange}
                defaultValue={selectedRole}
              />
            </div>
          </div>

          <h2 className="text-2xl font-semibold my-3">Kullanıcı Yetkileri</h2>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 my-3">
            <div className="col-span-1 md:col-span-1">
              <h3 className="text-lg font-semibold mb-2">Ekleme Yetkileri</h3>
              {yetkiList.slice(0, 8).map((yetki, index) => (
                <div key={index} className="flex items-center">
                  <input
                    type="checkbox"
                    id={yetki.Yetki}
                    checked={yetki.Deger === "true"}
                    onChange={(e) => handleYetkiChange(index, e.target.checked)}
                    className="mr-2"
                  />
                  <label htmlFor={yetki.Yetki}>{yetki.Yetki}</label>
                </div>
              ))}
            </div>
            <div className="col-span-1 md:col-span-1">
              <h3 className="text-lg font-semibold mb-2">
                Güncelleme Yetkileri
              </h3>
              {yetkiList.slice(8, 16).map((yetki, index) => (
                <div key={index + 8} className="flex items-center">
                  <input
                    type="checkbox"
                    id={yetki.Yetki}
                    checked={yetki.Deger === "true"}
                    onChange={(e) =>
                      handleYetkiChange(index + 8, e.target.checked)
                    }
                    className="mr-2"
                  />
                  <label htmlFor={yetki.Yetki}>{yetki.Yetki}</label>
                </div>
              ))}
            </div>
            <div className="col-span-1 md:col-span-1">
              <h3 className="text-lg font-semibold mb-2">Silme Yetkileri</h3>
              {yetkiList.slice(16).map((yetki, index) => (
                <div key={index + 16} className="flex items-center">
                  <input
                    type="checkbox"
                    id={yetki.Yetki}
                    checked={yetki.Deger === "true"}
                    onChange={(e) =>
                      handleYetkiChange(index + 16, e.target.checked)
                    }
                    className="mr-2"
                  />
                  <label htmlFor={yetki.Yetki}>{yetki.Yetki}</label>
                </div>
              ))}
            </div>
          </div>

          <div className="md:col-span-2 col-span-1">
            <button
              type="submit"
              className={`bg-primary-brand-color text-white-color px-4 py-2 rounded-md ${
                isFormSubmitted ? "" : "bg-opacity-50 cursor-not-allowed"
              }`}
              disabled={!isFormSubmitted}
            >
              Güncelle
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default UpdateKullaniciPopup;
