import React, { useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { useFormik } from "formik";
import { addKullaniciSchema } from "services/helpers/validations";
import { kullaniciTipData } from "data/data";
import KullaniciTipSelect from "./KullaniciTipSelect";
import { addKullanici } from "services/api/kullaniciApi/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AddKullaniciPopup({ onClose }) {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [selectedRole, setSelectedRole] = useState("Teknisyen");

  const handleRoleTipChange = (role) => {
    setSelectedRole(role.value);
  };

  useEffect(() => {}, []);

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    useFormik({
      initialValues: {
        AdSoyad: "",
        //Soyad: "",
        Email: "",
        Sifre: "",
        SifreTekrar: "",
      },

      onSubmit: async (values, bag) => {
        bag.resetForm();
        const AdSoyadSubmit = values.AdSoyad;
        //const SoyadSubmit = values.Soyad;
        const EmailSubmit = values.Email;
        const SifreSubmit = values.Sifre;
        const SifreTekrarSubmit = values.SifreTekrar;
        addKullaniciProcess(
          AdSoyadSubmit,
          EmailSubmit,
          SifreSubmit,
          SifreTekrarSubmit
        );
      },
      validationSchema: addKullaniciSchema,
    });

  useEffect(() => {
    if (Object.keys(touched).length > 0 && Object.keys(errors).length === 0) {
      setIsFormSubmitted(true);
    } else {
      setIsFormSubmitted(false);
    }
  }, [touched, errors]);

  const addKullaniciProcess = async (
    AdSoyadSubmit,
    EmailSubmit,
    SifreSubmit,
    SifreTekrarSubmit
  ) => {
    try {
      const addKullaniciApi = await addKullanici(
        AdSoyadSubmit,
        EmailSubmit,
        SifreSubmit,
        SifreTekrarSubmit,
        selectedRole
      );
      if (addKullaniciApi.succes) {
        console.log(
          "addKullaniciPopup -> addKullaniciProcess -> Kullanici Başarılı Şekilde Eklendi ",
          addKullaniciApi.basicAciklama
        );
        toast.success(addKullaniciApi.basicAciklama);
        onClose();
      } else {
        console.log(
          "addKullaniciPopup -> addKullaniciProcess -> Kullanici Eklenirken Hata: ",
          addKullaniciApi.basicAciklama
        );
        toast.error(addKullaniciApi.basicAciklama);
        onClose();
      }
    } catch (err) {
      console.log("addKullaniciPopup -> addKullaniciProcess -> Err: ", err);
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black-color bg-opacity-50">
      <div className="bg-white-color p-8 rounded-lg w-3/4 h-3/5 border-2 border-primary-brand-color overflow-auto">
        <div className="flex flex-row justify-between items-center mb-3">
          <h2 className="text-2xl font-semibold">Yeni Kullanıcı Ekle</h2>
          <button
            className=" text-xl text-primary-brand-color"
            onClick={onClose}
          >
            <IoMdClose />
          </button>
        </div>
        <hr />
        <form
          onSubmit={handleSubmit}
          className="grid grid-cols-1 md:grid-cols-2 gap-x-2 mt-4"
        >
          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="AdSoyad"
            >
              Kullanıcı Ad-Soyad
            </label>
            <input
              type="text"
              id="AdSoyad"
              name="AdSoyad"
              value={values.AdSoyad}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.AdSoyad && touched.AdSoyad && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.AdSoyad}
                </p>
              </div>
            )}
          </div>

          {/* <div className="mb-4">
            <label className="block text-sm font-semibold mb-1" htmlFor="Soyad">
              Kullanıcı Soyad
            </label>
            <input
              type="text"
              id="Soyad"
              name="Soyad"
              value={values.Soyad}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.Soyad && touched.Soyad && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.Soyad}
                </p>
              </div>
            )}
          </div> */}

          <div className="mb-4">
            <label className="block text-sm font-semibold mb-1" htmlFor="Email">
              Kullanıcı Email
            </label>
            <input
              type="text"
              id="Email"
              name="Email"
              value={values.Email}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.Email && touched.Email && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.Email}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-semibold mb-1" htmlFor="Sifre">
              Kullanıcı Şifre
            </label>
            <input
              type="text"
              id="Sifre"
              name="Sifre"
              value={values.Sifre}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.Sifre && touched.Sifre && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.Sifre}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="SifreTekrar"
            >
              Kullanıcı Şifre Tekrar
            </label>
            <input
              type="text"
              id="SifreTekrar"
              name="SifreTekrar"
              value={values.SifreTekrar}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.SifreTekrar && touched.SifreTekrar && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.SifreTekrar}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1 z-50"
              htmlFor="Role"
            >
              Kullanıcı Rol
            </label>
            <KullaniciTipSelect
              options={kullaniciTipData}
              onChange={handleRoleTipChange}
              defaultValue={selectedRole}
            />
          </div>

          <div className="md:col-span-2 col-span-1">
            <button
              type="submit"
              className={`bg-primary-brand-color text-white-color px-4 py-2 rounded-md ${
                isFormSubmitted ? "" : "bg-opacity-50 cursor-not-allowed"
              }`}
              disabled={!isFormSubmitted}
            >
              Kaydet
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddKullaniciPopup;
