import React from "react";

const TextWithLabel = ({
  label,
  value,
  lFontSize,
  vFontSize,
  lfontColor,
  param,
}) => {
  return (
    <div className="flex items-center ">
      <p className={`font-semibold mr-2 text-${lfontColor} text-lg`}>{label}</p>
      <p className="text-lg">{param}</p>
      <p className={` ml-2 text-lg text-black mr-4`}>{value}</p>
    </div>
  );
};

export default TextWithLabel;
