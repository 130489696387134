import React, { useState, useEffect } from "react";
import { getCihaz } from "services/api/cihazApi/api";
import CihazSelect from "./CihazSelect";
import { GetParcalar } from "services/api/yonetimApi/api";
import ParcaSelect from "./ParcaSelect";
import { useFormik } from "formik";
import { addServisCihazSchema } from "services/helpers/validations";
import PopupAddCihaz from "../deviceComponents/PopupAddCihaz";
import AddParcaPopup from "./AddParcaPopup";
import { updateServisCihazIslem } from "services/api/servisApi/api";

function UpdateCihazPopup({ onConfirm, onCancel, cihaz }) {
  const [cihazList, setCihazList] = useState([]);
  const [parcalarList, setParcalarList] = useState([]);
  const [selectedCihazId, setSelectedCihazId] = useState("");
  const [selectedParcaId, setSelectedParcaId] = useState("");
  const [cihazIslemList, setCihazIslemList] = useState(cihaz.ServisIslemleri);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedCihazLabel, setSelectedCihazLabel] = useState("");
  const [addCihazPopupShow, setAddCihazPopupShow] = useState(false);
  const [addParcaPopupShow, setAddParcaPopupShow] = useState(false);
  const [buttonPosition, setButtonPosition] = useState({ x: 0, y: 0 });
  const [yapilanIslemSubmit, setYapilanIslemSubmit] = useState("");
  const [islem, setIslem] = useState(null);
  const [selectedParca, setSelectedParca] = useState("");

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues: {
      YapilanIslem: "",
      Aciklama: "",
      MusteriAciklamasi: "",
    },
    onSubmit: async (values, bag) => {
      bag.resetForm();
      const YapilanIslemSubmit = values.YapilanIslem;
      setYapilanIslemSubmit(values.YapilanIslem);
      const AciklamaSubmit = values.Aciklama;
      const MusteriAciklamaSubmit = values.MusteriAciklamasi;

      console.log(
        YapilanIslemSubmit,
        AciklamaSubmit,
        MusteriAciklamaSubmit,
        selectedParcaId
      );
      updateCihazIslemProcess(
        YapilanIslemSubmit,
        AciklamaSubmit,
        MusteriAciklamaSubmit,
        selectedParcaId
      );
    },
    validationSchema: addServisCihazSchema,
  });

  useEffect(() => {
    //console.log("default Cihaz: ", cihaz);
    getCihazlarProcess();
    getParcalarProcess();
  }, [addCihazPopupShow, addParcaPopupShow, cihazIslemList]);

  useEffect(() => {
    if (Object.keys(touched).length > 0 && Object.keys(errors).length === 0) {
      // if (selectedSehirId && selectedIlceId && selectedMusteriTip) {
      setIsFormSubmitted(true);
      // }
    } else {
      setIsFormSubmitted(false);
    }
  }, [touched, errors]);

  const handleAddCihaz = () => {
    setAddCihazPopupShow(true);
  };

  const handleAddParca = (e) => {
    const buttonRect = e.target.getBoundingClientRect();
    setButtonPosition({ x: buttonRect.left, y: buttonRect.bottom });
    setAddParcaPopupShow(true);
  };

  const handleCihazChange = (cihaz) => {
    setSelectedCihazId(cihaz.value);
    setSelectedCihazLabel(cihaz.label);
  };

  const handleParcaChange = (parca) => {
    console.log(parca);
    setSelectedParcaId(parca.value);
  };

  const getCihazlarProcess = async () => {
    try {
      const getCihazlarApi = await getCihaz(
        true,
        1,
        1000,
        null,
        null,
        null,
        null,
        null
      );
      //console.log(getCihazlarApi.data);
      setCihazList(getCihazlarApi.data.data);
    } catch (error) {
      console.log("AddCihazPopup -> getCihazlarProcess -> Error: ", error);
    }
  };

  const getParcalarProcess = async () => {
    try {
      const getParcalarApi = await GetParcalar(1, 1000, null);
      setParcalarList(getParcalarApi.data.data);
    } catch (err) {
      console.log("AddCihazPopup -> getParcalarProcess -> Error: ", err);
    }
  };

  const updateCihazIslemProcess = async (
    yapilanIslemSubmit,
    aciklamaSubmit,
    musteriAciklamaSubmit,
    selectedParcaId
  ) => {
    //console.log("selectedParcaId", selectedParca);
    try {
      const updateCihaIslemApi = await updateServisCihazIslem(
        islem.ServisCihazIslemId,
        islem.ServisCihazId,
        selectedParcaId,
        yapilanIslemSubmit,
        aciklamaSubmit,
        musteriAciklamaSubmit
      );
      if (updateCihaIslemApi.succes) {
        console.log(
          "UpdateCihazPopup -> updateCihazIslemProcess -> Cihaz İşlem Başarılı Şekilde Güncellendi",
          updateCihaIslemApi.basicAciklama
        );
        console.log("Gelen Data: ", updateCihaIslemApi.data);
        setCihazIslemList(updateCihaIslemApi.data.ServisIslemleri);
        setSelectedParca(null);
      } else {
        console.log(
          "UpdateCihazPopup -> updateCihazIslemProcess -> Cihaz İşlem Güncellenirken Hata:",
          updateCihaIslemApi.basicAciklama
        );
      }
    } catch (error) {
      console.log(
        "UpdateCihazPopup -> updateCihazIslemProcess -> Error: ",
        error
      );
    }
  };

  const handleIslemClick = (islem) => {
    setIslem(islem);
    //console.log("İşlem ---->", islem);
    setFieldValue("YapilanIslem", islem.YapilanIslem);
    setFieldValue("Aciklama", islem.Aciklama);
    setFieldValue("MusteriAciklamasi", islem.MusteriAciklamasi);
    setSelectedParca(islem.Parca);
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black-color bg-opacity-50">
      <div className="bg-white-color p-8 rounded-lg w-3/4 h-5/6  h-max-5/6  border-2 border-primary-brand-color overflow-auto">
        <p className="text-2xl font-semibold mb-4">
          {"Cihaz Servis İşlem Güncelle"}
        </p>
        <hr />

        <p>
          <p className="text-red-color font-bold  text-xl">Uyarı!</p> İlgili
          Yapılacak İşlemi Seçmeden Güncelleme İşlemini Yapamazsınız. Devam
          Etmek İçin Lütfen Güncellemek İstediğiniz İşlemi Seçin!{" "}
        </p>

        <div className="mb-4 mt-2">
          <label className="block text-sm font-semibold mb-1" htmlFor="cihaz">
            Cihaz
          </label>
          {cihazList.length > 0 && (
            <CihazSelect
              options={cihazList}
              onChange={handleCihazChange}
              isDisabled={isDisabled}
              onClickButton={handleAddCihaz}
              filterOperation={false}
              defaultValue={cihaz}
              addOperation={false}
            />
          )}
        </div>

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-2 mt-4">
            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1"
                htmlFor="MusteriAciklamasi"
              >
                Müşteri Açıklama
              </label>
              <input
                type="text"
                id="MusteriAciklamasi"
                name="MusteriAciklamasi"
                value={values.MusteriAciklamasi}
                onChange={handleChange}
                onBlur={handleBlur}
                className="w-full border-2 border-gray-200 p-1 rounded-md"
              />
              {errors.MusteriAciklamasi && touched.MusteriAciklamasi && (
                <div>
                  <p className="font-semibold text-xs text-red-color">
                    {errors.MusteriAciklamasi}
                  </p>
                </div>
              )}
            </div>

            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1"
                htmlFor="Aciklama"
              >
                Servis Açıklama
              </label>
              <input
                type="text"
                id="Aciklama"
                name="Aciklama"
                value={values.Aciklama}
                onChange={handleChange}
                onBlur={handleBlur}
                className="w-full border-2 border-gray-200 p-1 rounded-md"
              />
              {errors.Aciklama && touched.Aciklama && (
                <div>
                  <p className="font-semibold text-xs text-red-color">
                    {errors.Aciklama}
                  </p>
                </div>
              )}
            </div>

            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1"
                htmlFor="parca"
              >
                Parça
              </label>
              {parcalarList.length > 0 && (
                <ParcaSelect
                  options={parcalarList}
                  onChange={handleParcaChange}
                  onClickButton={handleAddParca}
                  filterOperation={true}
                  addOperation={false}
                  isDisabled={isDisabled}
                  defaultValue={selectedParca}
                />
              )}
            </div>

            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1"
                htmlFor="YapilanIslem"
              >
                Yapılacak İşlem
              </label>
              <input
                type="text"
                id="YapilanIslem"
                name="YapilanIslem"
                value={values.YapilanIslem}
                onChange={handleChange}
                onBlur={handleBlur}
                className="w-full border-2 border-gray-200 p-1 rounded-md"
              />
              {errors.YapilanIslem && touched.YapilanIslem && (
                <div>
                  <p className="font-semibold text-xs text-red-color">
                    {errors.YapilanIslem}
                  </p>
                </div>
              )}
            </div>
          </div>
          <button
            type="submit"
            className={`bg-primary-brand-color text-white-color px-4 py-2 rounded-md mr-4 ${
              !islem ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={!islem}
          >
            İlgili İşlemi Güncelle
          </button>
        </form>
        <div className="mt-4">
          <h3 className="text-lg font-semibold mb-2">Yapılacak İşlemler</h3>
          <p className=" font-bold">
            Müşteri Açıklaması - Yapılan İşlem - Servis Açıklaması
          </p>
          <ul>
            {cihazIslemList.map((nesne, nesneIndex) => (
              <li
                onClick={() => handleIslemClick(nesne)}
                className=" list-decimal cursor-pointer underline hover:text-red-color"
                key={nesneIndex}
              >
                {nesne.MusteriAciklamasi} - {nesne.YapilanIslem} -{" "}
                {nesne.Aciklama}
              </li>
            ))}
          </ul>
        </div>
        <div className="flex justify-end mt-4">
          <button
            onClick={onCancel}
            className="bg-red-color text-white-color px-4 py-2 rounded-md mr-4"
          >
            Kapat
          </button>
        </div>
        {addCihazPopupShow && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <PopupAddCihaz onClose={() => setAddCihazPopupShow(false)} />
          </div>
        )}

        <AddParcaPopup
          isOpen={addParcaPopupShow}
          onClose={() => setAddParcaPopupShow(false)}
        />
      </div>
    </div>
  );
}

export default UpdateCihazPopup;
