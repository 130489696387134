import React from "react";

const IconButton = ({ onPress, bgColor, Icon, iconColor, textColor }) => {
  return (
    <button
      type="button"
      onClick={onPress}
      className={`bg-${bgColor} text-${textColor} p-2 rounded-md flex items-center justify-center mx-1`}
    >
      <span className={`text-${iconColor} text-2xl`}>
        <Icon />
      </span>
    </button>
  );
};

export default IconButton;
