import React, { useState } from "react";
import { useFormik } from "formik";
import { forgetPasswordSchema } from "services/helpers/validations";

const ForgetPasswordModal = ({ onClose, onSend }) => {
  const [email, setEmail] = useState("");
  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    useFormik({
      initialValues: {
        email: "",
      },
      onSubmit: async (values, bag) => {
        bag.resetForm();
        const EmailSubmit = values.email;
        onSend(EmailSubmit);
      },
      validationSchema: forgetPasswordSchema,
    });

  // const handleSubmitButton = (e) => {
  //   e.preventDefault();
  //   onSend(email);
  // };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 backdrop-blur">
      <div className="bg-white-color p-8 rounded-lg shadow-lg border-2">
        <h2 className="text-2xl font-bold mb-4">Şifremi Değiştir</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="text"
              id="email"
              name="email"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.email && touched.email && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.email}
                </p>
              </div>
            )}
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="mr-2 px-4 py-2 bg-red-color rounded-md text-white-color"
            >
              İptal
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-primary-brand-color text-white-color rounded-md"
            >
              Gönder
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgetPasswordModal;
