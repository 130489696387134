import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa"; // react-icons'dan FaTimes ikonunu içe aktarın
import { addModelSchema } from "services/helpers/validations";
import { useFormik } from "formik";
import { updateModel, getMarkalar } from "services/api/yonetimApi/api";

import MarkaSelect from "../deviceComponents/MarkaSelect";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpdateModelPopup = ({ isOpen, onClose, currentModel }) => {
  const [markaList, setMarkaList] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [selectedMarkaId, setSelectedMarkaId] = useState(currentModel.MarkaId);
  const [isDurum, setIsDurum] = useState(currentModel.Durum);

  const formik = useFormik({
    initialValues: {
      ModelAdi: currentModel.ModelAdi,
    },
    onSubmit: async (values, bag) => {
      bag.resetForm();
      const modelAdiSubmit = values.ModelAdi;
      updateModelProcess(modelAdiSubmit);
    },
    validationSchema: addModelSchema,
  });

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    formik;

  useEffect(() => {
    console.log(currentModel);
    getMarkalarProcess();
  }, []);

  useEffect(() => {
    validateForm();
  }, [values, errors]);

  const handleMarkaSelect = (marka) => {
    setSelectedMarkaId(marka.value);
    validateForm();
  };

  const validateForm = () => {
    if (values.ModelAdi && !errors.ModelAdi) {
      setIsFormSubmitted(true);
    } else {
      setIsFormSubmitted(false);
    }
  };

  const updateModelProcess = async (modelAdi) => {
    try {
      const updateModelApi = await updateModel(
        currentModel.ModelId,
        selectedMarkaId,
        modelAdi,
        isDurum
      );
      if (updateModelApi.succes) {
        console.log(
          "UpdateModalPopup -> updateModelProcess -> Model güncelleme başarılı",
          updateModelApi.basicAciklama
        );
        toast.success(updateModelApi.basicAciklama);
        onClose();
      } else {
        console.log(
          "UpdateModalPopup -> updateModelProcess -> Model güncellenirken bir hata oluştu",
          updateModelApi.basicAciklama
        );
        toast.error(updateModelApi.basicAciklama);
      }
    } catch (error) {
      console.log("UpdateModelPopup -> updateModelProcess -> Error: ", error);
    }
  };

  const getMarkalarProcess = async () => {
    try {
      const getMarkalarApi = await getMarkalar(1, 1000);
      if (getMarkalarApi.succes) {
        console.log("Markalar Getirildi", getMarkalarApi.data);
        setMarkaList(getMarkalarApi.data.data);
      } else {
        console.log(
          "AddMarkaPopup -> GetMarkalarProcess -> Markalar getirilirken Hata",
          getMarkalarApi.basicAciklama
        );
      }
    } catch (error) {
      console.log("AddMarkaPopup -> getMarkalarProcess -> Error: ", error);
    }
  };

  return (
    isOpen && (
      <div className="fixed inset-0 bg-black-color bg-opacity-50 flex justify-center items-center">
        <div className="bg-white-color p-8 rounded-lg w-2/4 h-3/6 border-2 border-primary-brand-color overflow-auto">
          <div className="flex justify-between">
            <h3 className="text-primary-brand-color font-serif font-bold text-lg">
              Model Güncelle
            </h3>
            <button onClick={onClose} className="focus:outline-none">
              <FaTimes className="text-primary-brand-color" />{" "}
              {/* Kapatma butonu için FaTimes ikonunu kullanın */}
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1"
                htmlFor="ModelAdi"
              >
                Model Adı
              </label>
              <input
                type="text"
                id="ModelAdi"
                name="ModelAdi"
                value={values.ModelAdi}
                onChange={(e) => {
                  handleChange(e);
                  validateForm();
                }}
                onBlur={handleBlur}
                className="w-full border-2 border-gray-200 p-1 rounded-md"
              />
              {errors.ModelAdi && touched.ModelAdi && (
                <div>
                  <p className="font-semibold text-xs text-red-color">
                    {errors.ModelAdi}
                  </p>
                </div>
              )}
            </div>

            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1"
                htmlFor="Cari"
              >
                Marka
              </label>
              {markaList.length !== 0 && (
                <MarkaSelect
                  options={markaList}
                  onChange={handleMarkaSelect}
                  defaultValue={currentModel.Marka}
                  addOperation={false}
                />
              )}
            </div>
            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1"
                htmlFor="Durum"
              >
                Durum
              </label>
              <div>
                <label style={{ marginRight: "10px" }}>
                  <input
                    type="radio"
                    name="Durum"
                    value="true"
                    checked={isDurum === true}
                    onChange={() => {
                      setIsDurum(true);
                      validateForm();
                    }}
                  />{" "}
                  Aktif
                </label>
                <label>
                  <input
                    type="radio"
                    name="Durum"
                    value="false"
                    checked={isDurum === false}
                    onChange={() => {
                      setIsDurum(false);
                      validateForm();
                    }}
                  />{" "}
                  Pasif
                </label>
              </div>
            </div>

            <button
              type="submit"
              className={`bg-green-color text-white-color px-4 py-2 rounded-md ${
                isFormSubmitted ? "" : "bg-opacity-50 cursor-not-allowed"
              }`}
              disabled={!isFormSubmitted}
            >
              Güncelle
            </button>
          </form>
        </div>
      </div>
    )
  );
};

export default UpdateModelPopup;
