import axios from "axios";
import axiosApiInstance from "../axiosClient";

import {
  GET_SEHIRLER,
  GET_ILCELER,
  ADD_MARKA,
  GET_MARKALAR,
  UPDATE_MARKA,
  DELETE_MARKA,
  GET_MODELLER,
  ADD_MODEL,
  DELETE_MODEL,
  UPDATE_MODEL,
  ADD_PARCA,
  GET_PARCALAR,
  UPDATE_PARCA,
  ADD_CIHAZ_TIP,
  GET_CIHAZ_TIPLERI,
  UPDATE_CIHAZ_TIP,
  ADD_ISLEM_DURUM,
  GET_ISLEM_DURUM,
  UPDATE_ISLEM_DURUM,
} from "services/helpers/contants";

export const getSehirler = async () => {
  try {
    const { data } = await axiosApiInstance.get(`${GET_SEHIRLER}`);

    if (data.BasicStatus === "Basarili") {
      return {
        success: true,
        basicAciklama: data.BasicAciklama,
        data: data.data,
      };
    } else {
      console.log(
        "yonetimApi -> api -> getSehirler -> Veri çekilirken hata:",
        data.BasicAciklama
      );
      return {
        success: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("yonetimApi -> api -> getSehirler -> Error:", err);
  }
};

export const getIlceler = async (sehirId) => {
  try {
    const { data } = await axiosApiInstance.get(
      `${GET_ILCELER}?sehirId=${sehirId}`
    );
    if (data.BasicStatus === "Basarili") {
      return {
        succes: true,
        basicAciklama: data.BasicAciklama,
        data: data.data,
      };
    } else {
      console.log(
        "yonetimApi -> api -> getIlceler -> Veri Çekilirken Hata: ",
        data.BasicAciklama
      );
      return {
        succes: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("yonetimApi -> api -> getIlceler -> Error:", err);
  }
};

export const getMarkalar = async (PageNumber, PageSize, Search) => {
  try {
    const { data } = await axiosApiInstance.post(`${GET_MARKALAR}`, {
      Durum: true,
      PageNumber: PageNumber,
      PageSize: PageSize,
      Search: Search,
    });
    if (data.BasicStatus === "Basarili") {
      return {
        succes: true,
        basicAciklama: data.BasicAciklama,
        data: data,
      };
    } else {
      console.log(
        "yonetimApi -> api -> getMarkalar -> Veri Çekilirken Hata: ",
        data.BasicAciklama
      );
      return {
        succes: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("yonetimApi -> api -> getMarkalar -> Error: ", err);
  }
};

export const GetParcalar = async (PageNumber, PageSize, ParcaId) => {
  try {
    const { data } = await axiosApiInstance.post(`${GET_PARCALAR}`, {
      Durum: true,
      PageNumber: PageNumber,
      PageSize: PageSize,
      ParcaId: ParcaId,
    });
    if (data.BasicStatus === "Basarili") {
      return {
        succes: true,
        basicAciklama: data.BasicAciklama,
        data: data,
      };
    } else {
      console.log(
        "yonetimApi -> api -> getParcalar -> Veri Çekilirken Hata: ",
        data.BasicAciklama
      );
      return {
        succes: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("yonetimApi -> api -> getParcalar -> Error:", err);
  }
};

export const getModeller = async (markaId, PageNumber, PageSize) => {
  try {
    const { data } = await axiosApiInstance.post(`${GET_MODELLER}`, {
      Durum: true,
      PageNumber: PageNumber,
      PageSize: PageSize,
      MarkaId: markaId,
    });
    if (data.BasicStatus === "Basarili") {
      return {
        succes: true,
        basicAciklama: data.BasicAciklama,
        data: data,
      };
    } else {
      console.log(
        "yonetimApi -> api -> getIlceler -> Veri Çekilirken Hata: ",
        data.BasicAciklama
      );
      return {
        succes: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("yonetimApi -> api -> getModeller -> Error: ", err);
  }
};

export const getCihazTipleri = async (PageNumber, PageSize, CihazTipId) => {
  try {
    const { data } = await axiosApiInstance.post(`${GET_CIHAZ_TIPLERI}`, {
      Durum: true,
      PageNumber: PageNumber,
      PageSize: PageSize,
      CihazTipId: CihazTipId,
    });
    if (data.BasicStatus === "Basarili") {
      return {
        succes: true,
        basicAciklama: data.BasicAciklama,
        data: data,
      };
    } else {
      console.log(
        "yonetimApi -> api -> getCıhazTipleri -> Veri Çekilirken Hata: ",
        data.BasicAciklama
      );
      return {
        succes: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("YonetimApi -> api getCihazTipleri -> Error: ", err);
  }
};

export const getIslemDurumlari = async () => {
  try {
    const { data } = await axiosApiInstance.get(`${GET_ISLEM_DURUM}`);
    if (data.BasicStatus === "Basarili") {
      return {
        succes: true,
        basicAciklama: data.BasicAciklama,
        data: data.data,
      };
    } else {
      console.log(
        "yonetimApi -> api -> getIslemDurumlari -> Veri Çekilirken Hata: ",
        data.BasicAciklama
      );
      return {
        succes: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("yonetimApi -> api -> getIslemDurumlari -> Error: ", err);
  }
};

export const addMarka = async (markaAdi, ureticiFirma, ithalatciFirmaId) => {
  console.log(markaAdi, ureticiFirma, ithalatciFirmaId);

  try {
    const { data } = await axiosApiInstance.post(`${ADD_MARKA}`, {
      MarkaAdi: markaAdi,
      UreticiFirma: ureticiFirma,
      IthalatciFirmaId: ithalatciFirmaId,
    });
    if (data.BasicStatus === "Basarili") {
      return {
        succes: true,
        basicAciklama: data.BasicAciklama,
      };
    } else {
      console.log(
        "yonetimApi -> api -> addMarka -> Marka Eklenirken Bir hata oldu. ",
        data.BasicAciklama
      );
      return {
        success: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("yonetimApi -> api -> addMarka -> Error: ", err);
  }
};

export const addModel = async (markaId, modelAdi) => {
  try {
    const { data } = await axiosApiInstance.post(`${ADD_MODEL}`, {
      MarkaId: markaId,
      ModelAdi: modelAdi,
    });
    if (data.BasicStatus === "Basarili") {
      return {
        succes: true,
        basicAciklama: data.BasicAciklama,
      };
    } else {
      console.log(
        "yonetimApi -> api -> addMarka -> Marka Eklenirken Bir hata oldu. ",
        data.BasicAciklama
      );
      return {
        success: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("YonetimApi -> api -> addModel -> Eroor:", err);
  }
};

export const addParca = async (parcaAdi, parcaTuru) => {
  try {
    const { data } = await axiosApiInstance.post(`${ADD_PARCA}`, {
      ParcaAdi: parcaAdi,
      Turu: parcaTuru,
    });
    if (data.BasicStatus === "Basarili") {
      return {
        succes: true,
        basicAciklama: data.BasicAciklama,
      };
    } else {
      console.log(
        "yonetimApi -> api -> addMarka -> Marka Eklenirken Bir hata oldu. ",
        data.BasicAciklama
      );
      return {
        success: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("YonetimApi -> api -> addParca -> Error: ", err);
  }
};

export const addCihazTip = async (cihazTipAdi) => {
  try {
    const { data } = await axiosApiInstance.post(`${ADD_CIHAZ_TIP}`, {
      CihazTipAdi: cihazTipAdi,
    });
    if (data.BasicStatus === "Basarili") {
      return {
        succes: true,
        basicAciklama: data.BasicAciklama,
      };
    } else {
      console.log(
        "yonetimApi -> api -> addCihazTip -> CihazTip Eklenirken Bir hata oldu. ",
        data.BasicAciklama
      );
      return {
        success: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("YonetimApi -> api -> addCihazTip -> Error: ", err);
  }
};

export const addIslemDurum = async (islemAdi, islemAciklama) => {
  try {
    const { data } = await axiosApiInstance.post(`${ADD_ISLEM_DURUM}`, {
      IslemAdi: islemAdi,
      IslemAciklama: islemAciklama,
    });
    if (data.BasicStatus === "Basarili") {
      return {
        succes: true,
        basicAciklama: data.BasicAciklama,
      };
    } else {
      console.log(
        "yonetimApi -> api -> addCihazTip -> CihazTip Eklenirken Bir hata oldu. ",
        data.BasicAciklama
      );
      return {
        success: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("YonetimApi -> api -> addIslemDurum -> Error: ", err);
  }
};

export const updateIslemDurum = async (
  islemDurumId,
  islemAdi,
  islemAciklama,
  isDurum
) => {
  try {
    const { data } = await axiosApiInstance.post(`${UPDATE_ISLEM_DURUM}`, {
      Durum: isDurum,
      IslemDurumId: islemDurumId,
      IslemAdi: islemAdi,
      IslemAciklama: islemAciklama,
    });
    if (data.BasicStatus === "Basarili") {
      return {
        succes: true,
        basicAciklama: data.BasicAciklama,
      };
    } else {
      console.log(
        "yonetimApi -> api -> updateIslemDuurum -> Islem Durum Güncellenirken Bir hata oldu. ",
        data.BasicAciklama
      );
      return {
        success: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("YonetimApi -> api -> updateIslemDurum -> Error: ", err);
  }
};

export const updateMarka = async (
  markaId,
  markaAdi,
  ureticiFirma,
  ithalatciFirmaId,
  isDurum
) => {
  try {
    const { data } = await axiosApiInstance.post(`${UPDATE_MARKA}`, {
      Durum: isDurum,
      MarkaId: markaId,
      MarkaAdi: markaAdi,
      UreticiFirma: ureticiFirma,
      IthalatciFirmaId: ithalatciFirmaId,
    });
    if (data.BasicStatus === "Basarili") {
      return {
        succes: true,
        basicAciklama: data.BasicAciklama,
      };
    } else {
      console.log(
        "yonetimApi -> api -> updateMarka -> Marka Güncellenirken Bir hata oldu. ",
        data.BasicAciklama
      );
      return {
        success: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("YonetimApi -> api -> updateMarka -> Error: ", err);
  }
};

export const updateParca = async (parcaId, parcaAdi, parcaTuru, isDurum) => {
  try {
    const { data } = await axiosApiInstance.post(`${UPDATE_PARCA}`, {
      Durum: isDurum,
      ParcaId: parcaId,
      ParcaAdi: parcaAdi,
      Turu: parcaTuru,
    });
    if (data.BasicStatus === "Basarili") {
      return {
        succes: true,
        basicAciklama: data.BasicAciklama,
      };
    } else {
      console.log(
        "yonetimApi -> api -> updateMarka -> Marka Güncellenirken Bir hata oldu. ",
        data.BasicAciklama
      );
      return {
        success: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("YonetimApi -> api -> updateParca -> Error: ", err);
  }
};

export const updateModel = async (modelId, markaId, modelAdi, isDurum) => {
  console.log("------------>", markaId);
  try {
    const { data } = await axiosApiInstance.post(`${UPDATE_MODEL}`, {
      Durum: isDurum,
      ModelId: modelId,
      MarkaId: markaId,
      ModelAdi: modelAdi,
    });
    if (data.BasicStatus === "Basarili") {
      return {
        succes: true,
        basicAciklama: data.BasicAciklama,
      };
    } else {
      console.log(
        "yonetimApi -> api -> updateModel -> ModelGüncellenirken Bir hata oldu. ",
        data.BasicAciklama
      );
      return {
        success: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("YonetimApi -> api -> updateModel -> Error: ", err);
  }
};

export const updateCihazTip = async (cihazTipId, cihazTipAdi, isDurum) => {
  try {
    const { data } = await axiosApiInstance.post(`${UPDATE_CIHAZ_TIP}`, {
      Durum: isDurum,
      CihazTipId: cihazTipId,
      CihazTipAdi: cihazTipAdi,
    });
    if (data.BasicStatus === "Basarili") {
      return {
        succes: true,
        basicAciklama: data.BasicAciklama,
      };
    } else {
      console.log(
        "yonetimApi -> api -> updateModel -> ModelGüncellenirken Bir hata oldu. ",
        data.BasicAciklama
      );
      return {
        success: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("Yonetimapi -> api -> updateCihazTip -> Error: ", err);
  }
};

export const deleteMarka = async (markaId) => {
  try {
    const { data } = await axiosApiInstance.post(
      `${DELETE_MARKA}?id=${markaId}`
    );
    if (data.BasicStatus === "Basarili") {
      return {
        success: true,
        basicAciklama: data.BasicAciklama,
      };
    } else {
      console.log(
        "yonetimApi -> api -> updateMarka -> Marka Güncellenirken Bir hata oldu. ",
        data.BasicAciklama
      );
      return {
        success: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("yonetimApi -> api -> deleteMarka -> Error: ", err);
  }
};

export const deleteModel = async (modelId) => {
  try {
    const { data } = await axiosApiInstance.post(
      `${DELETE_MODEL}?id=${modelId}`
    );
    if (data.BasicStatus === "Basarili") {
      return {
        success: true,
        basicAciklama: data.BasicAciklama,
      };
    } else {
      console.log(
        "yonetimApi -> api -> updateMarka -> Marka Güncellenirken Bir hata oldu. ",
        data.BasicAciklama
      );
      return {
        success: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("yonetimApi -> api -> deleteModel -> Error:", err);
  }
};
