import React, { useEffect, useState } from "react";
import Select from "react-select";

function KullaniciSelect({ options, onChange, defaultValue, addOperation }) {
  const [selectedOption, setSelectedOption] = useState(defaultValue);

  // react-select'in onChange olayı
  const handleKullaniciChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onChange(selectedOption); // Seçilen cari değeri üst bileşene iletmek için callback'i çağır
  };

  // react-select için seçeneklerin formatını hazırlama
  const kullaniciOptions = options.map((kullanici) => ({
    value: kullanici.KullaniciId,
    label: kullanici.NameSurname,
  }));

  useEffect(() => {
    //console.log(options);
  }, [options, defaultValue]);

  if (defaultValue != undefined) {
    defaultValue = kullaniciOptions.find(
      (x) => x.value == defaultValue.KullaniciId
    );
  }

  return (
    <>
      {addOperation == false ? (
        <div className="flex flex-row w-full justify-center items-center">
          <Select
            className="basic-single w-full"
            classNamePrefix="select"
            onChange={handleKullaniciChange}
            options={kullaniciOptions}
            placeholder="Teknisyen Seçin..."
            defaultValue={defaultValue != undefined && defaultValue}
          />
        </div>
      ) : (
        <div className="flex flex-row w-full justify-center items-center">
          <Select
            className="basic-single w-full"
            classNamePrefix="select"
            onChange={handleKullaniciChange}
            options={kullaniciOptions}
            placeholder="Teknisyen Seçin..."
            value={selectedOption}
          />
        </div>
      )}
    </>
  );
}

export default KullaniciSelect;
