import React, { useEffect, useState } from "react";
import { Header } from "./ui/generalComponents";
import PopupAddCihaz from "./ui/deviceComponents/PopupAddCihaz";
import { getCihaz } from "services/api/cihazApi/api";
import TableItemCihaz from "./ui/deviceComponents/TableItemCihaz";
import UpdateCihazPopup from "./ui/deviceComponents/UpdateCihazPopup";
import PopupFilterCihaz from "./ui/deviceComponents/PopupFilterCihaz";
import SearchBox from "./ui/generalComponents/SearchBox";

export default function DevicesSection() {
  const [showPopup, setShowPopup] = useState(false);
  const [cihazList, setCihazList] = useState([]);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [currentCihaz, setCurrentCihaz] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageNumber, setTotalPageNumber] = useState(null);
  const [filters, setFilters] = useState({
    durum: null,
    cariId: null,
    subeId: null,
    markaId: null,
    modelId: null,
    cihazTipDurum: null,
    search: null,
  });
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    getCihazProcess();
  }, [pageNumber, showPopup, showUpdatePopup, filters]);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const toggleUpdatePopup = () => {
    //setCurrentCihaz(cihaz); // Seçilen cari bilgisini state'e kaydediyoruz
    setShowUpdatePopup(!showUpdatePopup);
  };

  const toggleHandleUpdateClick = (e, cihaz) => {
    setCurrentCihaz(cihaz); // Seçilen cari bilgisini state'e kaydediyoruz
    setShowUpdatePopup(!showUpdatePopup);
  };

  const toggleFilterPopup = () => {
    setShowFilterPopup(!showFilterPopup);
  };

  const plusPageProcess = async () => {
    if (pageNumber < totalPageNumber) {
      setPageNumber(pageNumber + 1);
    }
  };

  const minusPageProcess = async () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const getCihazProcess = async () => {
    const { durum, cariId, subeId, markaId, modelId, cihazTipDurum, search } =
      filters;
    try {
      const getCihazApi = await getCihaz(
        durum,
        pageNumber,
        10,
        search,
        cariId,
        subeId,
        markaId,
        modelId,
        cihazTipDurum
      );
      if (getCihazApi.succes) {
        // console.log(
        //   "DevicesSection -> getCihazProcess -> Cihaz Başarılı Çekildi ",
        //   getCihazApi.basicAciklama
        // );
        setCihazList(getCihazApi.data.data);
        //console.log(getCihazApi.data);
        setTotalPageNumber(getCihazApi.data.TotalPage);
      } else {
        console.log(
          "DevicesSection -> getCihazProcess -> Cihaz cekilriken hata: ",
          getCihazApi.basicAciklama
        );
      }
    } catch (error) {
      console.log("DevicesSection -> getCihazProcess -> Error: ", error);
    }
  };

  const handleFilter = (filterValues) => {
    setFilters(filterValues);
    toggleFilterPopup();
    console.log(filterValues);
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
    if (term.length > 2) {
      setSearchTerm(term);
      console.log(term);
      setFilters((prevFilters) => ({
        ...prevFilters,
        search: term,
      }));
    } else {
      setSearchTerm(term);
      console.log("Term length is less than or equal to 4. Clearing search...");
      setFilters((prevFilters) => ({
        ...prevFilters,
        search: null,
      }));
    }
  };

  const clearFilter = () => {
    console.log("Deneme Deneme");
    setFilters({
      durum: null,
      cariId: null,
      subeId: null,
      markaId: null,
      modelId: null,
      cihazTipDurum: null,
      search: null,
    });
    setSearchTerm("");
  };

  return (
    <div className="w-full h-full px-2 overflow-auto">
      <Header
        title={"Cihazlar Listesi"}
        onAddClick={togglePopup}
        pageNumber={pageNumber}
        totalPageNumber={totalPageNumber}
        plusPage={plusPageProcess}
        minusPage={minusPageProcess}
        onFilterClick={toggleFilterPopup}
        isFilter={true}
        addTitle={"Cihaz Ekle"}
      />
      <div className="my-4">
        <SearchBox
          searchTerm={searchTerm}
          onSearch={handleSearch}
          clearFilter={clearFilter}
          placeholder={"Cihaz Tipi - Seri No"}
        />
      </div>
      <div className="mt-4">
        {cihazList.map((cihaz) => (
          <TableItemCihaz
            key={cihaz.CihazId}
            cihaz={cihaz}
            onUpdateClick={toggleHandleUpdateClick}
          />
        ))}
      </div>
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <PopupAddCihaz onClose={togglePopup} />
        </div>
      )}
      {showUpdatePopup && (
        <div className="fixed inset-0 bg-black-color bg-opacity-50 flex justify-center items-center">
          <UpdateCihazPopup
            onClose={toggleUpdatePopup}
            currentCihaz={currentCihaz}
          />
        </div>
      )}
      {showFilterPopup && (
        <div className="fixed inset-0 bg-black-color bg-opacity-50 flex justify-center items-center">
          <PopupFilterCihaz
            onClose={toggleFilterPopup}
            onFilter={handleFilter}
            searchTerm={searchTerm}
          />
        </div>
      )}
    </div>
  );
}
