import React, { useEffect, useState } from "react";
import Select from "react-select";
import { AddButton, IconButton } from "../generalComponents";
import { RiAddLine } from "react-icons/ri";

function CariSelect({
  options,
  onChange,
  defaultValue,
  onClickButton,
  addOperation,
  addButtonOperation = true,
}) {
  const [selectedOption, setSelectedOption] = useState(defaultValue);

  // react-select'in onChange olayı
  const handleCariChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onChange(selectedOption); // Seçilen cari değeri üst bileşene iletmek için callback'i çağır
  };

  useEffect(() => {
    //console.log(options);
  }, [options, defaultValue]);

  // react-select için seçeneklerin formatını hazırlama
  const cariOptions = options.map((cari) => ({
    value: cari.CariId,
    label: cari.CariUnvan,
  }));

  if (defaultValue == "var") {
    console.log(defaultValue);
    //defaultValue = cariOptions.find((x) => x.value == defaultValue.CariId);
    defaultValue = cariOptions[0];
    console.log(defaultValue);
  }

  const handleAddButtonClick = (e) => {
    e.preventDefault(); // Form gönderme davranışını durdur
    onClickButton(); // IconButtona tıklandığında çağrılacak işlevi çağır
  };

  return (
    <>
      {addOperation == false ? (
        <div className="flex flex-row w-full justify-center items-center">
          <Select
            className="basic-single w-full"
            classNamePrefix="select"
            onChange={handleCariChange}
            options={cariOptions}
            placeholder="Cari Seçin!"
            defaultValue={defaultValue != undefined && defaultValue}
            isDisabled={true}
          />
          {addButtonOperation == true && (
            <IconButton
              Icon={RiAddLine}
              bgColor={"primary-brand-color"}
              iconColor={"white-color"}
              onPress={handleAddButtonClick}
            />
          )}
        </div>
      ) : (
        <div className="flex flex-row w-full justify-center items-center">
          <Select
            className="basic-single w-full"
            classNamePrefix="select"
            onChange={handleCariChange}
            options={cariOptions}
            placeholder="Cari Seçin..."
            value={selectedOption}
            isDisabled={true}
          />
          <IconButton
            Icon={RiAddLine}
            bgColor={"primary-brand-color"}
            iconColor={"white-color"}
            onPress={handleAddButtonClick}
          />
        </div>
      )}
    </>
  );
}

export default CariSelect;
