import axios from "axios";
import {
  CHANGE_PASSWORD,
  FORGET_PASSWORD,
  LOGIN,
  REFRESH_LOGIN,
} from "services/helpers/contants";
import axiosApiInstance from "../axiosClient";

export const loginUser = async (email, sifre) => {
  try {
    const { data } = await axios.post(`${LOGIN}`, {
      Email: email,
      Password: sifre,
    });

    if (data.BasicStatus === "Basarili") {
      return {
        succes: true,
        token: data.data.AccessToken,
        refreshToken: data.data.RefreshToken,
        role: data.data.Roles[0],
        basicAciklama: data.BasicAciklama,
      };
    } else {
      console.log(
        "authApi -> api -> loginUser -> Giriş Yaparken Hata:",
        data.BasicAciklama
      );
      return {
        succes: false,
        basicStatus: data.BasicStatus,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("AuthApi -> api -> loginUser -> Err", err);
  }
};

export const refreshTokenUser = async (refreshTokens) => {
  const response = await axios.post(
    `${REFRESH_LOGIN}?refreshToken=${refreshTokens}`
  );
  const token = response.data.data.AccessToken;
  const refreshToken = response.data.data.RefreshToken;
  const basicStatus = response.data.BasicStatus;

  localStorage.setItem("refreshToken", refreshToken);
  localStorage.setItem("userToken", token);

  if (basicStatus === "Basarili") {
    return {
      token: token,
      refreshToken: refreshToken,
    };
  }
};

export const forgetPassword = async (email) => {
  try {
    const { data } = await axiosApiInstance.get(
      `${FORGET_PASSWORD}?email=${email}`
    );
    if (data.BasicStatus === "Basarili") {
      console.log(
        "Authapi -> forgetPassword -> Email'e Gönderilirken Başarılı ",
        data.BasicAciklama
      );
      return {
        succes: true,
        basicAciklama: data.BasicAciklama,
      };
    } else {
      console.log(
        "Authapi -> forgetPassword -> Email'e Gönderilirken Hata Oluştu: ",
        data.BasicAciklama
      );
      return {
        succes: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("Authapi -> forgetPassword -> Error: ", err);
  }
};

export const changePassword = async (email, token, sifre, sifreTekrar) => {
  try {
    const { data } = await axiosApiInstance.post(`${CHANGE_PASSWORD}`, {
      Email: email,
      Token: token,
      Sifre: sifre,
      SifreTekrar: sifreTekrar,
    });
    if (data.BasicStatus === "Basarili") {
      console.log(
        "Authapi -> changePassword -> Şifre Değiştirilirken Hata ",
        data.BasicAciklama
      );
      return {
        succes: true,
        basicAciklama: data.BasicAciklama,
      };
    } else {
      console.log(
        "Authapi -> forgetPassword -> Şifre Değiştirilirken Hata ",
        data.BasicAciklama
      );
      return {
        succes: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("Authapi -> api -> changePassword -> Error: ", err);
  }
};
