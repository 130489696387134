import React, { useEffect } from "react";
import {
  FaCheck,
  FaExpandArrowsAlt,
  FaTimes,
  FaPencilAlt,
} from "react-icons/fa";

import { useNavigate } from "react-router-dom";

function TableItemCihaz({ cihaz, onUpdateClick }) {
  const navigate = useNavigate();
  const icon = cihaz.Durum ? (
    <FaCheck className="text-green-color" />
  ) : (
    <FaTimes className="text-red-color" />
  );
  //const icon = <FaCheck />;

  const handleUpdateClick = (e) => {
    onUpdateClick(e, cihaz); // Seçilen cari öğesini PopupUpdateCari bileşenine iletiyoruz.
  };

  useEffect(() => {
    //console.log(cihaz);
  }, []);

  return (
    <div className="p-4 mb-4 rounded-md flex flex-row items-center justify-between border-2 border-primary-brand-color cursor-pointer">
      <div className="flex flex-row items-center">
        <div className="border-2 border:primary-brand-color mr-2 p-3  flex justify-center items-center  rounded-full">
          {icon}
        </div>
        <div className="flex flex-row items-center">
          <div className="flex flex-col">
            <h2 className="text-xl font-serif font-semibold ">
              {cihaz.CihazAdi}
            </h2>
            <h3 className=" text-base">{`Cari: ${cihaz.Cari.CariUnvan}`}</h3>
          </div>
        </div>
      </div>
      <div>
        <button
          onClick={(e) => {
            handleUpdateClick(e);
          }}
          className="  text-primary-brand-color px-2 py-2 border-2 border-primary-brand-color rounded-full text-lg mr-4"
        >
          <FaPencilAlt />
        </button>
      </div>
    </div>
  );
}

export default TableItemCihaz;
