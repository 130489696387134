import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa"; // react-icons'dan FaTimes ikonunu içe aktarın
import { addMarkaSchema } from "services/helpers/validations";
import { useFormik } from "formik";
import { addMarka } from "services/api/yonetimApi/api";
import { getCari } from "services/api/cariApi/api";
import CariSelect from "../deviceComponents/CariSelect";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddMarkaPopup = ({ isOpen, onClose, buttonPosition }) => {
  // const [formData, setFormData] = useState({
  //   MarkaAdi: "",
  //   UreticiFirma: "",
  // });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [selectedCariId, setSelectedCariId] = useState(null);
  const [cariList, setCariList] = useState([]);
  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    useFormik({
      initialValues: {
        MarkaAdi: "",
        UreticiFirma: "",
      },
      onSubmit: async (values, bag) => {
        bag.resetForm();
        const MarkaAdiSubmit = values.MarkaAdi;
        const UreticiFirmaSubmit = values.UreticiFirma;
        addMarkaProcess(MarkaAdiSubmit, UreticiFirmaSubmit);
      },
      validationSchema: addMarkaSchema,
    });

  useEffect(() => {
    getCariler();
  }, []);

  useEffect(() => {
    if (Object.keys(touched).length > 0 && Object.keys(errors).length === 0) {
      setIsFormSubmitted(true);
    } else {
      setIsFormSubmitted(false);
    }
  }, [touched, errors]);

  const handleCariSelect = (cari) => {
    setSelectedCariId(cari.value);
  };

  const addMarkaProcess = async (markaAdi, ureticiFirma) => {
    try {
      const addMarkaApi = await addMarka(
        markaAdi,
        ureticiFirma,
        selectedCariId
      );
      if (addMarkaApi.succes) {
        console.log("Marka Eklendi", addMarkaApi.basicAciklama);
        toast.success(addMarkaApi.basicAciklama);
        onClose();
      } else {
        console.log(
          "AddMarkaPopup -> addMarkaProcess -> Marka Eklenirken Bir Hata Oldu",
          addMarkaApi.basicAciklama
        );
        toast.error(addMarkaApi.basicAciklama);
      }
    } catch (error) {
      console.log("AddMarkaPopup -> addMarkaProcess -> Error: ", error);
    }
  };

  const getCariler = async () => {
    //console.log("getCariler Öncesi");
    try {
      const getCarilerApi = await getCari(
        true,
        1,
        1000,
        null,
        ["Distributor", "Satici"],
        null,
        null,
        null
      );
      //console.log(getCarilerApi);
      if (getCarilerApi.success) {
        //console.log("Cariler Getirildi", getCarilerApi.data.data);
        setCariList(getCarilerApi.data.data);
      } else {
        console.log(
          "AddMarkaPopup -> GetCariler -> Cariler getirilirken Hata",
          getCarilerApi.basicAciklama
        );
      }
    } catch (error) {
      console.log("AddMarkaPopup -> getCariler -> Error: ", error);
    }
  };

  return (
    isOpen && (
      <div className="fixed inset-0 bg-black-color bg-opacity-50 flex justify-center items-center">
        <div className="bg-white-color p-8 rounded-lg w-2/4 h-3/6 border-2 border-primary-brand-color overflow-auto">
          <div className="flex justify-between">
            <h3 className="text-primary-brand-color font-serif font-bold text-lg">
              Marka Ekle
            </h3>
            <button onClick={onClose} className="focus:outline-none">
              <FaTimes className="text-primary-brand-color" />{" "}
              {/* Kapatma butonu için FaTimes ikonunu kullanın */}
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1"
                htmlFor="MarkaAdi"
              >
                Marka Adı
              </label>
              <input
                type="text"
                id="MarkaAdi"
                name="MarkaAdi"
                value={values.MarkaAdi}
                onChange={handleChange}
                onBlur={handleBlur}
                className="w-full border-2 border-gray-200 p-1 rounded-md"
              />
              {errors.MarkaAdi && touched.MarkaAdi && (
                <div>
                  <p className="font-semibold text-xs text-red-color">
                    {errors.MarkaAdi}
                  </p>
                </div>
              )}
            </div>
            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1"
                htmlFor="UreticiFirma"
              >
                Üretici Firma
              </label>
              <input
                type="text"
                id="UreticiFirma"
                name="UreticiFirma"
                value={values.UreticiFirma}
                onChange={handleChange}
                onBlur={handleBlur}
                className="w-full border-2 border-gray-200 p-1 rounded-md"
              />
              {errors.UreticiFirma && touched.UreticiFirma && (
                <div>
                  <p className="font-semibold text-xs text-red-color">
                    {errors.UreticiFirma}
                  </p>
                </div>
              )}
            </div>
            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1"
                htmlFor="Cari"
              >
                Cari
              </label>
              <CariSelect options={cariList} onChange={handleCariSelect} />
            </div>
            <button
              type="submit"
              className={`bg-green-color text-white-color px-4 py-2 rounded-md ${
                isFormSubmitted ? "" : "bg-opacity-50 cursor-not-allowed"
              }`}
              disabled={!isFormSubmitted}
            >
              Kaydet
            </button>
          </form>
        </div>
      </div>
    )
  );
};

export default AddMarkaPopup;

// işlem durum tab eklenicek
