import React, { useEffect, useState } from "react";
import { getCari } from "services/api/cariApi/api";
import TableItem from "./ui/cariComponents/TableItem";
// import Header from "./ui/cariComponents/Header";
import PopupAddCari from "./ui/cariComponents/PopupAddCari";
import PopupUpdateCari from "./ui/cariComponents/PopupUpdateCari";
import { Header } from "./ui/generalComponents";
import PopupFilterCari from "./ui/cariComponents/PopupFilterCari";
import SearchBox from "./ui/generalComponents/SearchBox";

function CariSection() {
  const [caris, setCaris] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [currentCari, setCurrentCari] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageNumber, setTotalPageNumber] = useState(null);
  const [filters, setFilters] = useState({
    sehirId: null,
    ilceId: null,
    durum: null,
    musteriTip: null,
    cariStatus: null,
    search: null,
  });
  const [searchTerm, setSearchTerm] = useState("");

  const getCariProcess = async () => {
    try {
      const { sehirId, ilceId, durum, musteriTip, cariStatus, search } =
        filters;
      const getCariApi = await getCari(
        durum,
        pageNumber,
        10,
        search,
        musteriTip,
        cariStatus,
        sehirId,
        ilceId
      );
      if (getCariApi.success) {
        setCaris(getCariApi.data.data);
        //console.log(getCariApi);
        setTotalPageNumber(getCariApi.data.TotalPage);
      }
    } catch (error) {
      console.log("CariSection -> getCariProcess -> Error: ", error);
    }
  };

  const plusPageProcess = async () => {
    if (pageNumber < totalPageNumber) {
      setPageNumber(pageNumber + 1);
    }
  };

  const minusPageProcess = async () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  useEffect(() => {
    getCariProcess();
    console.log("ne zaman çalışıyorsun");
  }, [pageNumber, showPopup, showUpdatePopup, filters]);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const toggleUpdatePopup = (cari) => {
    setCurrentCari(cari); // Seçilen cari bilgisini state'e kaydediyoruz
    setShowUpdatePopup(!showUpdatePopup);
  };

  const toggleFilterPopup = () => {
    setShowFilterPopup(!showFilterPopup);
  };

  const handleFilter = (filterValues) => {
    setFilters(filterValues);
    toggleFilterPopup(); // Popup'u kapat
    console.log(filterValues);
  };

  const clearFilter = () => {
    console.log("Deneme Deneme");
    setFilters({
      sehirId: null,
      ilceId: null,
      durum: null,
      musteriTip: null,
      cariStatus: null,
      search: null,
    });
    setSearchTerm("");
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
    if (term.length > 3) {
      setSearchTerm(term);
      console.log(term);
      setFilters((prevFilters) => ({
        ...prevFilters,
        search: term,
      }));
    } else {
      setSearchTerm(term);
      console.log("Term length is less than or equal to 4. Clearing search...");
      setFilters((prevFilters) => ({
        ...prevFilters,
        search: null,
      }));
    }
  };

  return (
    <div className="h-full w-full px-2 overflow-auto">
      <Header
        title={"Cari Listesi"}
        onAddClick={togglePopup}
        pageNumber={pageNumber}
        totalPageNumber={totalPageNumber}
        plusPage={plusPageProcess}
        minusPage={minusPageProcess}
        onFilterClick={toggleFilterPopup}
        isFilter={true}
        addTitle={"Cari Ekle"}
      />
      <div className="my-4">
        <SearchBox
          searchTerm={searchTerm}
          onSearch={handleSearch}
          clearFilter={clearFilter}
          placeholder={"Cari Unvan - Vergi No - Tc No"}
        />
      </div>
      <div className="mt-4">
        {caris.map((cari) => (
          <TableItem
            key={cari.CariId}
            cari={cari}
            onUpdateClick={toggleUpdatePopup}
          />
        ))}
      </div>
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <PopupAddCari onClose={togglePopup} />
        </div>
      )}
      {showUpdatePopup && (
        <div className="fixed inset-0 bg-black-color bg-opacity-50 flex justify-center items-center">
          <PopupUpdateCari
            onClose={toggleUpdatePopup}
            currentCari={currentCari}
          />
        </div>
      )}
      {showFilterPopup && (
        <div className="fixed inset-0 bg-black-color bg-opacity-50 flex justify-center items-center">
          <PopupFilterCari
            onClose={toggleFilterPopup}
            onFilter={handleFilter}
            searchTerm={searchTerm}
          />
        </div>
      )}
    </div>
  );
}

export default CariSection;
