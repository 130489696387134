import React, { useEffect, useState } from "react";
import Header from "./ui/cariComponents/Header";
import { useLocation } from "react-router-dom";
import { getSube } from "services/api/cariApi/api";
import TableItem from "./ui/subelerComponents/TableItem";
import PopupAddSube from "./ui/subelerComponents/PopupAddSube";
import PopupUpdateSube from "./ui/subelerComponents/PopupUpdateSube";
import { MesajModalWithIcon } from "./ui/generalComponents";
import { AiOutlineInfoCircle } from "react-icons/ai";

function CariSubelerSection() {
  const location = useLocation();
  const cari = location.state.cari;
  const [subes, setSubes] = useState([]);
  const [currentCari, setCurrentCari] = useState();
  const [currentSube, setCurrentSube] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);

  useEffect(() => {
    console.log(cari);
    setCurrentCari(cari);
    getSubeProcess(cari.CariId);
  }, [showPopup, showUpdatePopup]);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const toggleUpdatePopup = (sube) => {
    setCurrentSube(sube); // Seçilen cari bilgisini state'e kaydediyoruz
    setShowUpdatePopup(!showUpdatePopup);
  };

  const getSubeProcess = async (cariId) => {
    try {
      const getSubeApi = await getSube(cariId);
      setSubes(getSubeApi.data);
    } catch (error) {
      console.log("CariSubelerSection -> getSubeProcess -> Error:", error);
    }
  };

  return (
    <div className="h-full w-full px-2 overflow-auto">
      <Header
        title={"Lokasyonlar"}
        onAddClick={togglePopup}
        addTitle={"Lokasyon Ekle"}
        isFilter={false}
      />

      {subes.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-full w-full">
          <MesajModalWithIcon
            Icon={AiOutlineInfoCircle}
            message="Şu anda görüntülenecek şube yok."
          />
        </div>
      ) : (
        <div className="mt-4">
          {subes.map((sube) => (
            <TableItem
              key={sube.SubeId}
              sube={sube}
              onUpdateClick={toggleUpdatePopup}
            />
          ))}
        </div>
      )}

      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <PopupAddSube
            onClose={togglePopup}
            currentCari={currentCari}
            isSection={true}
          />
        </div>
      )}
      {showUpdatePopup && (
        <div className="fixed inset-0 bg-black-color bg-opacity-50 flex justify-center items-center">
          <PopupUpdateSube
            onClose={toggleUpdatePopup}
            currentSube={currentSube}
          />
        </div>
      )}
    </div>
  );
}

export default CariSubelerSection;
