import React from "react";
import {
  TabPanel,
  Tab,
  AddMarkaView,
  AddModelView,
  AddParcaView,
  AddCihazTipiView,
  AddIslemDurumView,
} from "./ui/managerComponents";

export default function ManagerSection() {
  return (
    <div className=" w-full h-full px-4 overflow-auto">
      <TabPanel>
        <Tab label="Marka">
          <AddMarkaView />
        </Tab>
        <Tab label="Model">
          <AddModelView />
        </Tab>
        <Tab label="Parça">
          <AddParcaView />
        </Tab>
        <Tab label="Cihaz Tipi">
          <AddCihazTipiView />
        </Tab>
        {/* <Tab label="İşlem Durum">
          <AddIslemDurumView />
        </Tab> */}
      </TabPanel>
    </div>
  );
}
