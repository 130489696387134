import React, { useEffect, useState } from "react";
import {
  Route,
  Routes,
  Navigate,
  BrowserRouter,
  useNavigate,
} from "react-router-dom";
import LoginPage from "components/LoginPage";
import AdminPage from "components/AdminPage";
import UserPage from "components/UserPage";
import ServiceSection from "components/ServiceSection";
import MeetingSection from "components/MeetingSection";
import CariSection from "components/CariSection";
import RaporSection from "components/RaporSection";
import DevicesSection from "components/DevicesSection";
import UsersSection from "components/UsersSection";
import SettingsSection from "components/SettingsSection";
import CariSubelerSection from "components/CariSubelerSection";
import ManagerSection from "components/ManagerSection";
import PasswordPage from "components/PasswordPage";
import UserDevicePage from "components/UserDevicePage";
import UserServisPage from "components/UserServisPage";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const [role, setRole] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const userToken = localStorage.getItem("userToken");
    const refreshToken = localStorage.getItem("refreshToken");
    const userRole = localStorage.getItem("role");

    if (userToken && refreshToken) {
      setRole(userRole);
      if (
        userRole === "SuperAdmin" ||
        userRole === "Teknisyen" ||
        userRole === "Admin" ||
        userRole === "Muhasebe"
      ) {
        navigate("/admin/servisler");
      } else if (userRole === "Musteri") {
        navigate("/user/servislerim");
      }
    } else {
      //navigate("/");
    }
  }, []);

  useEffect(() => {
    console.log(role);
  }, [role]);

  return (
    <Routes>
      <Route path="/" element={<LoginPage setRole={setRole} />} />
      <Route path="/yeniSifreOlustur" element={<PasswordPage />} />
      <Route
        path="/user"
        element={role === "Musteri" ? <UserPage /> : <Navigate to="/" />}
      >
        <Route path="/user/servislerim" element={<UserServisPage />} />
        <Route path="/user/cihazlarim" element={<UserDevicePage />} />
      </Route>
      <Route
        path="/admin"
        element={
          role === "SuperAdmin" ||
          role === "Teknisyen" ||
          role === "Admin" ||
          role === "Muhasebe" ? (
            <AdminPage />
          ) : (
            <Navigate to="/" />
          )
        }
      >
        <Route path="/admin/servisler" element={<ServiceSection />} />
        <Route path="/admin/randevular" element={<MeetingSection />} />
        <Route path="/admin/cari" element={<CariSection />} />
        <Route path="/admin/raporlar" element={<RaporSection />} />
        <Route path="/admin/cihazlar" element={<DevicesSection />} />
        <Route path="/admin/yonetim" element={<ManagerSection />} />
        <Route path="/admin/kullanicilar" element={<UsersSection />} />
        <Route path="/admin/ayarlar" element={<SettingsSection />} />
        <Route path="/admin/cari/subeler" element={<CariSubelerSection />} />
      </Route>
    </Routes>
  );
};

const Root = () => (
  <BrowserRouter>
    <App />
    <ToastContainer theme="colored" />
  </BrowserRouter>
);

export default Root;
