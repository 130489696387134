import React, { useEffect, useState } from "react";
import Select from "react-select";

function CihazTipSelect({
  options,
  onChange,
  defaultValue,
  addOperation,
  disabled,
}) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isDefaultValue, setIsDefaultValue] = useState(false);

  const handleCihazTipChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onChange(selectedOption);
  };

  useEffect(() => {}, [defaultValue]);

  const cihazTipOptions = options.map((cihazTip) => ({
    value: cihazTip.CihazTipId,
    label: cihazTip.CihazTipAdi,
  }));

  if (defaultValue != undefined) {
    defaultValue = cihazTipOptions.find(
      (x) => x.value == defaultValue.CihazTipId
    );
  }

  return (
    <>
      {addOperation == false ? (
        <div className="flex flex-row w-full justify-center items-center">
          <Select
            className="basic-single w-full"
            classNamePrefix="select"
            defaultValue={defaultValue != undefined && defaultValue}
            onChange={handleCihazTipChange}
            options={cihazTipOptions}
            placeholder="Cihaz Tip Seçin..."
            isDisabled={disabled}
          />
        </div>
      ) : (
        <div className="flex flex-row w-full justify-center items-center">
          <Select
            className="basic-single w-full"
            classNamePrefix="select"
            value={selectedOption}
            onChange={handleCihazTipChange}
            options={cihazTipOptions}
            placeholder="Cihaz Tip Seçin..."
            isDisabled={disabled}
          />
        </div>
      )}
    </>
  );
}

export default CihazTipSelect;
