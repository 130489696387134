import React, { useState, useEffect } from "react";
import { Header } from "../generalComponents";
import AddCihazTipiPopup from "./AddCihazTipiPopup";
import { getCihazTipleri } from "services/api/yonetimApi/api";
import TableItemCihazTip from "./TableItemCihazTip";
import UpdateCihazTipPopup from "./UpdateCihazTipPopup";

function AddCihazTipiView() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [buttonPosition, setButtonPosition] = useState({ x: 0, y: 0 });
  const [cihazTipList, setCihazTipList] = useState([]);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [currentCihazTip, setCurrentCihazTip] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageNumber, setTotalPageNumber] = useState(null);

  useEffect(() => {
    getCihazTipleriProcess();
  }, [pageNumber, isPopupOpen, showUpdatePopup]);

  const plusPageProcess = async () => {
    if (pageNumber < totalPageNumber) {
      setPageNumber(pageNumber + 1);
    }
  };

  const minusPageProcess = async () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleAddClick = (e) => {
    // Butonun konumunu alın
    const buttonRect = e.target.getBoundingClientRect();
    setButtonPosition({ x: buttonRect.left, y: buttonRect.bottom });

    // Popup'u açın
    setIsPopupOpen(true);
  };

  const handleClose = () => {
    // Popup'u kapatın
    setIsPopupOpen(false);
  };

  const handleUpdateClose = () => {
    setShowUpdatePopup(false);
  };

  const toggleUpdatePopup = (e, cihazTip) => {
    const buttonRect = e.target.getBoundingClientRect();
    setButtonPosition({ x: buttonRect.right, y: buttonRect.top });

    setCurrentCihazTip(cihazTip); // Seçilen cari bilgisini state'e kaydediyoruz
    setShowUpdatePopup(!showUpdatePopup);
  };

  const getCihazTipleriProcess = async () => {
    try {
      const getCihazTipApi = await getCihazTipleri(pageNumber, 10, null);
      if (getCihazTipApi.succes) {
        // console.log(
        //   "AddCihazTipiView -> getCihazTipleriProcess ->  Cihaz Tipleri Başarılı şekilde cekildi",
        //   getCihazTipApi.basicAciklama
        // );
        //console.log(getCihazTipApi.data);
        setCihazTipList(getCihazTipApi.data.data);
        setTotalPageNumber(getCihazTipApi.data.TotalPage);
      } else {
        console.log(
          "AddCihazTipiView -> getCihazTipleriProcess -> Cihaz Tiplerini Cekerken Hata OLIUŞTU",
          getCihazTipApi.basicAciklama
        );
      }
    } catch (error) {
      console.log(
        "AddCihazTipiView -> getCihazTipleriProcess ->  Error: ",
        error
      );
    }
  };

  return (
    <div className="w-full h-full">
      <Header
        title={"Cihaz Tip Listesi"}
        onAddClick={handleAddClick}
        pageNumber={pageNumber}
        totalPageNumber={totalPageNumber}
        plusPage={plusPageProcess}
        minusPage={minusPageProcess}
        isFilter={false}
        addTitle={"Cihaz Tip Ekle"}
      />
      <AddCihazTipiPopup
        isOpen={isPopupOpen}
        onClose={handleClose}
        buttonPosition={buttonPosition}
      />
      <div className="mt-4">
        {cihazTipList.map((cihazTip) => (
          <TableItemCihazTip
            key={cihazTip.CihazTipId}
            cihazTip={cihazTip}
            onUpdateClick={(e, cihazTip) => {
              toggleUpdatePopup(e, cihazTip);
            }}
          />
        ))}
      </div>
      {showUpdatePopup && (
        <div className="fixed inset-0 bg-black-color bg-opacity-50 flex justify-center items-center">
          <UpdateCihazTipPopup
            isOpen={showUpdatePopup}
            onClose={handleUpdateClose}
            currentCihazTip={currentCihazTip}
            buttonPosition={buttonPosition}
          />
        </div>
      )}
    </div>
  );
}

export default AddCihazTipiView;
