import React, { useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { getIlceler, getSehirler } from "services/api/yonetimApi/api";
import { SehirSelect, IlceSelect, ConfirmModal } from "../generalComponents";
import { deleteSube, updateSube } from "services/api/cariApi/api";
import { useFormik } from "formik";
import { addSubeSchema } from "services/helpers/validations";

function PopupUpdateSube({ onClose, currentSube }) {
  const [sehirlerList, setSehirlerList] = useState([]);
  const [ilcelerList, setIlcelerList] = useState([]);
  const [selectedSehirId, setSelectedSehirId] = useState(currentSube.SehirId);
  const [selectedIlceId, setSelectedIlceId] = useState(currentSube.IlceId);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
    console.log(currentSube);
    getSehirlerProcess();
  }, []);

  const handleSilClick = () => {
    setShowConfirmModal(true);
  };

  const handleConfirmSil = () => {
    // Silme işlemi burada gerçekleştirilecek
    deleteCariProcess();
    console.log("Cari silindi");
    onClose();
  };

  const handleCancelSil = () => {
    setShowConfirmModal(false);
  };

  const deleteCariProcess = async () => {
    try {
      const deleteCariApi = await deleteSube(currentSube.SubeId);
      console.log(deleteCariApi.basicAciklama);
    } catch (error) {
      console.log("PopupUpdateCari -> deleteCariProcess -> Error: ", error);
    }
  };

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    useFormik({
      initialValues: {
        SubeAdi: currentSube.SubeAdi,
        VergiDairesi: currentSube.VergiDairesi,
        VergiNo: currentSube.VergiNo,
        Adres: currentSube.Adres,
        Telefon: currentSube.Telefon,
        Email: currentSube.Email,
        YetkiliAd: currentSube.Yetkili,
        YetkiliGsm: currentSube.YetkiliGsm,
      },

      onSubmit: async (values, bag) => {
        bag.resetForm();
        const SubeAdiSubmit = values.SubeAdi;
        const VergiDairesiSubmit = values.VergiDairesi;
        const VergiNoSubmit = values.VergiNo;
        const AdresSubmit = values.Adres;
        const TelefonSubmit = values.Telefon;
        const EmailSubmit = values.Email;
        const YetkiliAdSubmit = values.YetkiliAd;
        const YetkiliGsmSubmit = values.YetkiliGsm;

        updateSubeProcess(
          SubeAdiSubmit,
          VergiDairesiSubmit,
          VergiNoSubmit,
          AdresSubmit,
          TelefonSubmit,
          EmailSubmit,
          YetkiliAdSubmit,
          YetkiliGsmSubmit
        );
      },
      validationSchema: addSubeSchema,
    });

  useEffect(() => {
    if (Object.keys(touched).length > 0 && Object.keys(errors).length === 0) {
      if (selectedSehirId && selectedIlceId) {
        setIsFormSubmitted(true);
      }
    } else {
      setIsFormSubmitted(false);
    }
  }, [touched, errors]);

  const handleSehirChange = (sehirId) => {
    setSelectedSehirId(sehirId);

    getIlceProcess(sehirId);
  };

  const handleIlceChange = (ilceId) => {
    setSelectedIlceId(ilceId);
  };

  const getIlceProcess = async (sehirId) => {
    try {
      const getIlcelerApi = await getIlceler(sehirId);

      setIlcelerList(getIlcelerApi.data);
    } catch (error) {
      console.log("PopupAddCari -> getIlcelerProcess -> Error: ", error);
    }
  };

  const getSehirlerProcess = async () => {
    try {
      const getSehirlerApi = await getSehirler();
      //console.log(getSehirlerApi.data);
      setSehirlerList(getSehirlerApi.data);
    } catch (error) {
      console.log("PopupUpdateCari -> getSehirlerProcess -> Error: ", error);
    }
  };

  const updateSubeProcess = async (
    SubeAdiSubmit,
    VergiDairesiSubmit,
    VergiNoSubmit,
    AdresSubmit,
    TelefonSubmit,
    EmailSubmit,
    YetkiliAdSubmit,
    YetkiliGsmSubmit
  ) => {
    try {
      const updateSubeApi = await updateSube(
        currentSube.SubeId,
        currentSube.CariId,
        SubeAdiSubmit,
        VergiDairesiSubmit,
        VergiNoSubmit,
        selectedSehirId,
        selectedIlceId,
        AdresSubmit,
        TelefonSubmit,
        EmailSubmit,
        YetkiliAdSubmit,
        YetkiliGsmSubmit
      );
      if (updateSubeApi.success) {
        console.log("Sube Güncellendi");
        onClose();
      } else {
        console.log("Sube Güncellenemedi");
      }
    } catch (error) {
      console.log("PopupUpdateSube -> updateSubeProcess -> Error: ", error);
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black-color bg-opacity-50">
      <div className="bg-white-color p-8 rounded-lg w-3/4 h-4/6 border-2 border-primary-brand-color overflow-auto">
        <div className="flex flex-row justify-between items-center mb-3">
          <h2 className="text-2xl font-semibold">Lokasyon Detay & Güncelle</h2>
          <button
            className=" text-xl text-primary-brand-color"
            onClick={onClose}
          >
            <IoMdClose />
          </button>
        </div>
        <hr />
        <form
          onSubmit={handleSubmit}
          className="grid grid-cols-1 md:grid-cols-2 gap-x-2 mt-4"
        >
          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="SubeAd"
            >
              Lokasyon Adı
            </label>
            <input
              type="text"
              id="SubeAdi"
              name="SubeAdi"
              value={values.SubeAdi}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.SubeAdi && touched.SubeAdi && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.SubeAdi}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="VergiDairesi"
            >
              Vergi Dairesi
            </label>
            <input
              type="text"
              id="VergiDairesi"
              name="VergiDairesi"
              value={values.VergiDairesi}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.VergiDairesi && touched.VergiDairesi && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.VergiDairesi}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="VergiNo"
            >
              Vergi No
            </label>
            <input
              type="text"
              id="VergiNo"
              name="VergiNo"
              value={values.VergiNo}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.VergiNo && touched.VergiNo && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.VergiNo}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="Telefon"
            >
              Telefon
            </label>
            <input
              type="text"
              id="Telefon"
              name="Telefon"
              value={values.Telefon}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.Telefon && touched.Telefon && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.Telefon}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-semibold mb-1" htmlFor="Email">
              E-mail
            </label>
            <input
              type="text"
              id="Email"
              name="Email"
              value={values.Email}
              onBlur={handleBlur}
              onChange={handleChange}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.Email && touched.Email && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.Email}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="YetkiliAd"
            >
              Yetkili Ad
            </label>
            <input
              type="text"
              id="YetkiliAd"
              name="YetkiliAd"
              value={values.YetkiliAd}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.YetkiliAd && touched.YetkiliAd && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.YetkiliAd}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="YetkiliGsm"
            >
              Yetkili Telefon
            </label>
            <input
              type="text"
              id="YetkiliGsm"
              name="YetkiliGsm"
              value={values.YetkiliGsm}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.YetkiliGsm && touched.YetkiliGsm && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.YetkiliGsm}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-semibold mb-1" htmlFor="Şehir">
              Şehir
            </label>
            {sehirlerList.length != 0 && (
              <SehirSelect
                options={sehirlerList}
                onChange={handleSehirChange}
                defaultValue={currentSube.Sehir}
                addOperation={false}
              />
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-semibold mb-1" htmlFor="İlce">
              İlçe
            </label>
            <IlceSelect
              options={ilcelerList}
              onChange={handleIlceChange}
              defaultValue={currentSube.Ilce}
              defaultValueSehir={currentSube.Sehir}
              selectedSehirId={selectedSehirId}
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-semibold mb-1" htmlFor="Adres">
              Adres
            </label>
            <textarea
              type="text"
              id="Adres"
              name="Adres"
              value={values.Adres}
              onBlur={handleBlur}
              onChange={handleChange}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
              rows={3}
            />
            {errors.Adres && touched.Adres && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.Adres}
                </p>
              </div>
            )}
          </div>

          <div className="md:col-span-2 col-span-1 flex justify-end">
            <button
              type="button"
              onClick={handleSilClick}
              className="bg-red-color text-white-color px-4 py-2 rounded-md hover:bg-opacity-80 mr-4"
            >
              Pasife Al
            </button>
            {showConfirmModal && (
              <ConfirmModal
                message="Bu Şubeyi Pasif Hale Getirmek İstediğinizden Emin misiniz?"
                onConfirm={handleConfirmSil}
                onCancel={handleCancelSil}
              />
            )}
            <button
              type="submit"
              className={`bg-primary-brand-color text-white-color px-4 py-2 rounded-md ${
                isFormSubmitted ? "" : "bg-opacity-50 cursor-not-allowed"
              }`}
              disabled={!isFormSubmitted}
            >
              Güncelle
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PopupUpdateSube;
