import React, { useState, useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import tr from "date-fns/locale/tr"; // Türkçe dil dosyasını içe aktarın
import "react-datepicker/dist/react-datepicker.css";
import { IoMdClose } from "react-icons/io";
import {
  getCihazTipleri,
  getIlceler,
  getMarkalar,
  getModeller,
  getSehirler,
} from "services/api/yonetimApi/api";
import { addCari, getCari, getSube } from "services/api/cariApi/api";
import { useFormik } from "formik";
import { addCihazSchema } from "services/helpers/validations";
import CariSelect from "./CariSelect";
import SubeSelect from "./SubeSelect";
import MarkaSelect from "./MarkaSelect";
import ModelSelect from "./ModelSelect";
import CihazTipSelect from "./CihazTipSelect";
import { addCihaz, updateCihaz } from "services/api/cihazApi/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

registerLocale("tr", tr); // 'tr' ismiyle kaydedin

function UpdateCihazPopup({ onClose, currentCihaz }) {
  const [cariList, setCariList] = useState([]);
  const [isDurum, setIsDurum] = useState(currentCihaz.Durum);
  const [subelerList, setSubelerList] = useState([]);
  const [markalarList, setMarkalarList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [cihazTipList, setCihazTipList] = useState([]);
  const [selectedCariId, setSelectedCariId] = useState(
    currentCihaz.Cari.CariId
  );
  const [selectedSubeId, setSelectedSubeId] = useState(
    currentCihaz.Sube && currentCihaz.Sube.SubeId
      ? currentCihaz.Sube.SubeId
      : null
  );
  const [selectedMarkaId, setSelectedMarkaId] = useState(
    currentCihaz.Marka.MarkaId
  );
  const [selectedModelId, setSelectedModelId] = useState(
    currentCihaz.Model.ModelId
  );
  const [selectedCihazTipId, setSelectedCihazTipId] = useState(
    currentCihaz.CihazTip.CihazTipId
  );
  const [selectedDate, setSelectedDate] = useState(
    currentCihaz.GarantiBaslangici
  );
  const [satisSelectedDate, setSatisSelectedDate] = useState(
    currentCihaz.SatisTarihi
  );
  const [isFormSubmitted, setIsFormSubmitted] = useState(
    currentCihaz.GarantiBaslangici
  );

  useEffect(() => {
    console.log("------>", currentCihaz);
    getCarilerProcess();
    getMarkalarProcess();
    getCihazTipProcess();
  }, []);

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    useFormik({
      initialValues: {
        CihazAdi: currentCihaz.CihazAdi,
        SeriNo: currentCihaz.SeriNo,
        //UretimYili: currentCihaz.UretimYili,
        Barkod: currentCihaz.Barkod,
        FaturaNo: currentCihaz.FaturaNo,
        //GarantiYili: currentCihaz.GarantiYili,
        Aciklama: currentCihaz.Aciklama,
      },

      onSubmit: async (values, bag) => {
        bag.resetForm();
        const cihazAdiSubmit = values.CihazAdi;
        const seriNoSubmit = values.SeriNo;
        const barkodSubmit = values.Barkod;
        const faturaNoSubmit = values.FaturaNo;
        const aciklamaSubmit = values.Aciklama;

        updateCihazProcess(
          cihazAdiSubmit,
          seriNoSubmit,
          //uretimYiliSubmit,
          barkodSubmit,
          faturaNoSubmit,
          //garantiYiliSubmit,
          aciklamaSubmit
        );
      },
      validationSchema: addCihazSchema,
    });

  const updateCihazProcess = async (
    cihazAdiSubmit,
    seriNoSubmit,
    //uretimYiliSubmit,
    barkodSubmit,
    faturaNoSubmit,
    //garantiYiliSubmit,
    aciklamaSubmit
  ) => {
    try {
      const updateCihazApi = await updateCihaz(
        isDurum,
        currentCihaz.FirmaCihazId,
        selectedCariId,
        cihazAdiSubmit,
        selectedMarkaId,
        selectedModelId,
        seriNoSubmit,
        //uretimYiliSubmit,
        barkodSubmit,
        selectedSubeId,
        selectedCihazTipId,
        satisSelectedDate,
        faturaNoSubmit,
        selectedDate,
        //garantiYiliSubmit,
        aciklamaSubmit
      );
      if (updateCihazApi.success) {
        console.log(
          "UpdateCihazPopup -> updateCihazProcess -> Cihaz Başarılı Güncellendi ",
          updateCihazApi.basicAciklama
        );
        toast.success(updateCihazApi.basicAciklama);
        onClose();
      } else {
        console.log(
          "UpdateCihazPopup -> updateCihazProcess -> Cihaz Güncellenirken Hata: ",
          updateCihazApi.basicAciklama
        );
        toast.error(updateCihazApi.basicAciklama);
      }
    } catch (error) {
      console.log("UpdateCihazPopup -> updateCihazProcess -> Error: ", error);
    }
  };

  // useEffect(() => {
  //   if (Object.keys(touched).length > 0 && Object.keys(errors).length === 0) {
  //     // if (selectedSehirId && selectedIlceId && selectedMusteriTip) {
  //     setIsFormSubmitted(true);
  //     // }
  //   } else {
  //     setIsFormSubmitted(false);
  //   }
  // }, [touched, errors]);

  useEffect(() => {
    if (
      selectedCariId &&
      selectedMarkaId &&
      selectedModelId &&
      selectedCihazTipId &&
      values.SeriNo &&
      values.CihazAdi
    ) {
      setIsFormSubmitted(true);
    } else {
      setIsFormSubmitted(false);
    }
  }, [
    selectedCariId,
    selectedMarkaId,
    selectedModelId,
    selectedCihazTipId,
    values.CihazAdi,
    values.SeriNo,
  ]);

  const getCarilerProcess = async () => {
    try {
      const getCarilerApi = await getCari(
        true,
        1,
        1000,
        null,
        null,
        null,
        null
      );
      if (getCarilerApi.success) {
        // console.log(
        //   "PopupAddCihaz -> getCarilerProcess -> Cariler Başarılı Bir Şekilde Çekildi",
        //   getCarilerApi.basicAciklama
        // );
        setCariList(getCarilerApi.data.data);
      } else {
        console.log(
          "PopupAddCihaz -> getCarilerProcess -> Cariler Cekilirken Bir Hata Oluştu.",
          getCarilerApi.basicAciklama
        );
      }
    } catch (error) {
      console.log("PopupAddCihaz -> getCarilerProcess -> Error: ", error);
    }
  };

  const getSubeProcess = async (cariId) => {
    try {
      const getSubelerApi = await getSube(cariId, 1, 1000);
      if (getSubelerApi.success) {
        console.log(
          "PopupAddCihaz -> getSubeProcess -> Şubeler Çekildi",
          getSubelerApi.basicAciklama
        );
        setSubelerList(getSubelerApi.data.data);
      } else {
        console.log(
          "PopupAddCihaz -> getSubeProcess -> Şubeler Çekilirken Hata",
          getSubelerApi.basicAciklama
        );
      }
    } catch (error) {
      console.log("PopupAddCihaz -> getSubeProcess -> Error: ", error);
    }
  };

  const getMarkalarProcess = async () => {
    try {
      const getMarkalarApi = await getMarkalar(1, 1000);
      if (getMarkalarApi.succes) {
        // console.log(
        //   "PopupAddCihaz -> getMarkaProcess -> Markalar Başarılı Şekilde Çekildi ",
        //   getMarkalarApi.basicAciklama
        // );
        setMarkalarList(getMarkalarApi.data.data);
      } else {
        console.log(
          "PopupAddCihaz -> getMarkaProcess -> Markalar Çekilirken Hata  ",
          getMarkalarApi.basicAciklama
        );
      }
    } catch (error) {
      console.log("PopupAddCihaz -> getMarkaProcess -> Error: ", error);
    }
  };

  const getModelProcess = async (markaId) => {
    try {
      const getModelApi = await getModeller(markaId, 1, 1000);
      if (getModelApi.succes) {
        console.log(
          "PopupAddCihaz -> getModelProcess -> Modeller Başarılı Çekildi",
          getModelApi.basicAciklama
        );
        console.log("<----->", getModelApi.data.data);
        setModelList(getModelApi.data.data);
      } else {
        console.log(
          "PopupAddCihaz -> getModelProcess -> Model Çekilirken Hata:",
          getModelApi.basicAciklama
        );
      }
    } catch (error) {
      console.log("PopupAddCihaz -> getModelProcess -> Error: ", error);
    }
  };

  const getCihazTipProcess = async () => {
    try {
      const getCihazTipApi = await getCihazTipleri(1, 1000, null);
      if (getCihazTipApi.succes) {
        // console.log(
        //   "PopupAddCihaz -> getCihazTipProcess -> Cihaz Tipleri Başarılı Çekildi",
        //   getCihazTipApi.basicAciklama
        // );
        setCihazTipList(getCihazTipApi.data.data);
      } else {
        console.log(
          "PopupAddCihaz -> getCihazTipProcess -> Cihaz Tipleri Çekilirken Hata:",
          getCihazTipApi.basicAciklama
        );
      }
    } catch (error) {
      console.log("PopupAddCihaz -> getCihazTipProcess -> Error: ", error);
    }
  };

  const handleCihazTipChange = (cihazTip) => {
    setSelectedCihazTipId(cihazTip.value);
  };

  const handleCariChange = (cari) => {
    setSelectedCariId(cari.value);
    getSubeProcess(cari.value);
  };

  const handleSubeChange = (sube) => {
    setSelectedSubeId(sube.value);
  };

  const handleModelChange = (model) => {
    setSelectedModelId(model.value);
  };

  const handleMarkaChange = (marka) => {
    setSelectedMarkaId(marka.value);
    getModelProcess(marka.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSatisDateChange = (date) => {
    setSatisSelectedDate(date);
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black-color bg-opacity-50">
      <div className="bg-white-color p-8 rounded-lg w-3/4 h-5/6 border-2 border-primary-brand-color overflow-auto">
        <div className="flex flex-row justify-between items-center mb-3">
          <h2 className="text-2xl font-semibold">Cihaz Güncelle</h2>
          <button
            className=" text-xl text-primary-brand-color"
            onClick={onClose}
          >
            <IoMdClose />
          </button>
        </div>
        <hr />
        <div className="my-4">
          <label className="block text-sm font-semibold mb-1" htmlFor="Durum">
            Durum
          </label>
          <div>
            <label style={{ marginRight: "10px" }}>
              <input
                type="radio"
                name="Durum"
                value="true"
                checked={isDurum === true}
                onChange={() => setIsDurum(true)}
              />{" "}
              Aktif
            </label>
            <label>
              <input
                type="radio"
                name="Durum"
                value="false"
                checked={isDurum === false}
                onChange={() => setIsDurum(false)}
              />{" "}
              Pasif
            </label>
          </div>
        </div>
        <form
          onSubmit={handleSubmit}
          className="grid grid-cols-1 md:grid-cols-2 gap-x-2 mt-4"
        >
          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="CihazAdi"
            >
              Cihaz Adı
            </label>
            <input
              type="text"
              id="CihazAdi"
              name="CihazAdi"
              value={values.CihazAdi}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.CihazAdi && touched.CihazAdi && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.CihazAdi}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="SeriNo"
            >
              Seri No
            </label>
            <input
              type="text"
              id="SeriNo"
              name="SeriNo"
              value={values.SeriNo}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.SeriNo && touched.SeriNo && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.SeriNo}
                </p>
              </div>
            )}
          </div>

          {/* <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="UretimYili"
            >
              Üretim Yılı
            </label>
            <input
              type="text"
              id="UretimYili"
              name="UretimYili"
              value={values.UretimYili}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.UretimYili && touched.UretimYili && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.UretimYili}
                </p>
              </div>
            )}
          </div> */}

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="FaturaNo"
            >
              Fatura No
            </label>
            <input
              type="text"
              id="FaturaNo"
              name="FaturaNo"
              value={values.FaturaNo}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.FaturaNo && touched.FaturaNo && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.FaturaNo}
                </p>
              </div>
            )}
          </div>

          {/* <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="GarantiYili"
            >
              GarantiYili
            </label>
            <input
              type="text"
              id="GarantiYili"
              name="GarantiYili"
              value={values.GarantiYili}
              onBlur={handleBlur}
              onChange={handleChange}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.GarantiYili && touched.GarantiYili && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.GarantiYili}
                </p>
              </div>
            )}
          </div> */}

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="Barkod"
            >
              Barkod
            </label>
            <input
              type="text"
              id="Barkod"
              name="Barkod"
              value={values.Barkod}
              onBlur={handleBlur}
              onChange={handleChange}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.Barkod && touched.Barkod && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.Barkod}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-semibold mb-1" htmlFor="Cari">
              Cari
            </label>
            {cariList.length != 0 && (
              <CariSelect
                options={cariList}
                onChange={handleCariChange}
                defaultValue={currentCihaz.Cari}
                addOperation={false}
              />
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-semibold mb-1" htmlFor="Sube">
              Lokasyon
            </label>
            <SubeSelect
              options={subelerList}
              onChange={handleSubeChange}
              defaultValue={currentCihaz.Sube}
              defaultValueCari={currentCihaz.Cari}
              selectedCariId={selectedCariId}
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-semibold mb-1" htmlFor="Marka">
              Marka
            </label>
            {markalarList.length != 0 && (
              <MarkaSelect
                options={markalarList}
                onChange={handleMarkaChange}
                defaultValue={currentCihaz.Marka}
                addOperation={false}
              />
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-semibold mb-1" htmlFor="Model">
              Model
            </label>
            <ModelSelect
              options={modelList}
              onChange={handleModelChange}
              defaultValue={currentCihaz.Model}
              defaultValueMarka={currentCihaz.Marka}
              selectedMarkaId={selectedMarkaId}
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="CihazTip"
            >
              Cihaz Tip
            </label>
            {cihazTipList.length != 0 && (
              <CihazTipSelect
                options={cihazTipList}
                onChange={handleCihazTipChange}
                defaultValue={currentCihaz.CihazTip}
                addOperation={false}
              />
            )}
          </div>

          <div className="mb-4 flex flex-col">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="GarantiTarihi"
            >
              Garanti Başlangıç Tarihi
            </label>
            <DatePicker
              className="w-full border-2 border-gray-200 p-1 rounded-md"
              selected={selectedDate}
              onChange={handleDateChange}
              dateFormat="dd/MM/yyyy" // Tarih formatını ayarla (isteğe bağlı)
              placeholderText="Tarih Seçin"
              locale="tr"
            />
          </div>

          <div className="mb-4 flex flex-col">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="SatisTarihi"
            >
              Cihaz Satış Tarihi
            </label>
            <DatePicker
              className="w-full border-2 border-gray-200 p-1 rounded-md"
              selected={satisSelectedDate}
              onChange={handleSatisDateChange}
              dateFormat="dd/MM/yyyy" // Tarih formatını ayarla (isteğe bağlı)
              placeholderText="Tarih Seçin"
              locale="tr"
            />
          </div>

          <div className="mb-1">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="Aciklama"
            >
              Açıklama
            </label>
            <textarea
              type="text"
              id="Aciklama"
              name="Aciklama"
              value={values.Aciklama}
              onBlur={handleBlur}
              onChange={handleChange}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
              rows={3}
            />
            {errors.Aciklama && touched.Aciklama && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.Aciklama}
                </p>
              </div>
            )}
          </div>

          <div className="md:col-span-2 col-span-1">
            <button
              type="submit"
              className={`bg-green-color text-white-color px-4 py-2 rounded-md ${
                isFormSubmitted ? "" : "bg-opacity-50 cursor-not-allowed"
              }`}
              disabled={!isFormSubmitted}
            >
              Güncelle
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default UpdateCihazPopup;
