import React, { useState } from "react";

const TabPanel = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="w-full">
      <div className="flex">
        {React.Children.map(children, (child, index) => (
          <button
            className={`${
              index === activeTab
                ? "bg-secondary-brand-color text-white-color"
                : "bg-primary-brand-color text-white-color"
            } py-2 px-4 mr-2 rounded`}
            onClick={() => handleTabClick(index)}
          >
            {child.props.label}
          </button>
        ))}
      </div>
      <div className="mt-4">
        {React.Children.map(children, (child, index) => (
          <div style={{ display: index === activeTab ? "block" : "none" }}>
            {child.props.children}
          </div>
        ))}
      </div>
    </div>
  );
};

const Tab = ({ label, children }) => {
  return <>{children}</>;
};

export { TabPanel, Tab };
