import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa"; // react-icons'dan FaTimes ikonunu içe aktarın
import { addMarkaSchema } from "services/helpers/validations";
import { useFormik } from "formik";
import {
  addMarka,
  deleteMarka,
  updateMarka,
} from "services/api/yonetimApi/api";
import { getCari } from "services/api/cariApi/api";
import CariSelect from "../deviceComponents/CariSelect";
import { ConfirmModal } from "../generalComponents";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpdateMarkaPopup = ({
  isOpen,
  onClose,
  buttonPosition,
  currentMarka,
}) => {
  const [isDurum, setIsDurum] = useState(currentMarka.Durum);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [selectedCariId, setSelectedCariId] = useState(
    currentMarka.IthalatciFirmaId
  );
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [cariList, setCariList] = useState([]);
  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    useFormik({
      initialValues: {
        MarkaAdi: currentMarka.MarkaAdi,
        UreticiFirma: currentMarka.UreticiFirma,
      },
      onSubmit: async (values, bag) => {
        bag.resetForm();
        const MarkaAdiSubmit = values.MarkaAdi;
        const UreticiFirmaSubmit = values.UreticiFirma;
        updateMarkaProcess(MarkaAdiSubmit, UreticiFirmaSubmit);
      },
      validationSchema: addMarkaSchema,
    });

  useEffect(() => {
    getCariler();
  }, []);

  useEffect(() => {
    if (values.MarkaAdi && values.UreticiFirma) {
      setIsFormSubmitted(true);
    } else {
      setIsFormSubmitted(false);
    }
  }, [values.MarkaAdi, values.UreticiFirma]);

  const handleCariSelect = (cari) => {
    setSelectedCariId(cari.value);
  };

  const updateMarkaProcess = async (markaAdi, ureticiFirma) => {
    try {
      const updateMarkaApi = await updateMarka(
        currentMarka.MarkaId,
        markaAdi,
        ureticiFirma,
        selectedCariId,
        isDurum
      );
      if (updateMarkaApi.succes) {
        console.log("Veriler Başarılı Bir Şekilde Güncellendi");
        toast.success(updateMarkaApi.basicAciklama);
        onClose();
      } else {
        console.log(
          "UpdateMarkaPopup -> updateMarkaProcess -> Veriler Güncellenirken Hata: ",
          updateMarkaApi.basicAciklama
        );
        toast.error(updateMarkaApi.basicAciklama);
      }
    } catch (error) {
      console.log("UpdateMarkaPopup -> updateMarkaProcess -> Error:", error);
    }
  };

  const getCariler = async () => {
    try {
      const getCarilerApi = await getCari(
        true,
        1,
        1000,
        null,
        ["Distributor", "Satici"],
        null,
        null,
        null
      );
      if (getCarilerApi.success) {
        console.log("Cariler Getirildi", getCarilerApi.data);
        setCariList(getCarilerApi.data.data);
      } else {
        console.log(
          "AddMarkaPopup -> GetCariler -> Cariler getirilirken Hata",
          getCarilerApi.basicAciklama
        );
      }
    } catch (error) {
      console.log("AddMarkaPopup -> getCariler -> Error: ", error);
    }
  };

  return (
    isOpen && (
      <div className="fixed inset-0 bg-black-color bg-opacity-50 flex justify-center items-center">
        <div className="bg-white-color p-8 rounded-lg w-2/4 h-3/6 border-2 border-primary-brand-color overflow-auto">
          <div className="flex justify-between">
            <h3 className="text-primary-brand-color font-serif font-bold text-lg">
              Marka Güncelle
            </h3>
            <button onClick={onClose} className="focus:outline-none">
              <FaTimes className="text-primary-brand-color" />{" "}
              {/* Kapatma butonu için FaTimes ikonunu kullanın */}
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1"
                htmlFor="MarkaAdi"
              >
                Marka Adı
              </label>
              <input
                type="text"
                id="MarkaAdi"
                name="MarkaAdi"
                value={values.MarkaAdi}
                onChange={handleChange}
                onBlur={handleBlur}
                className="w-full border-2 border-gray-200 p-1 rounded-md"
              />
              {errors.MarkaAdi && touched.MarkaAdi && (
                <div>
                  <p className="font-semibold text-xs text-red-color">
                    {errors.MarkaAdi}
                  </p>
                </div>
              )}
            </div>
            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1"
                htmlFor="UreticiFirma"
              >
                Üretici Firma
              </label>
              <input
                type="text"
                id="UreticiFirma"
                name="UreticiFirma"
                value={values.UreticiFirma}
                onChange={handleChange}
                onBlur={handleBlur}
                className="w-full border-2 border-gray-200 p-1 rounded-md"
              />
              {errors.UreticiFirma && touched.UreticiFirma && (
                <div>
                  <p className="font-semibold text-xs text-red-color">
                    {errors.UreticiFirma}
                  </p>
                </div>
              )}
            </div>
            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1"
                htmlFor="Cari"
              >
                Ithalatcı Firma
              </label>
              {cariList.length != 0 && (
                <CariSelect
                  options={cariList}
                  onChange={handleCariSelect}
                  defaultValue={currentMarka.IthalatciFirma}
                  addOperation={false}
                />
              )}
            </div>
            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1"
                htmlFor="Durum"
              >
                Durum
              </label>
              <div>
                <label style={{ marginRight: "10px" }}>
                  <input
                    type="radio"
                    name="Durum"
                    value="true"
                    checked={isDurum === true}
                    onChange={() => setIsDurum(true)}
                  />{" "}
                  Aktif
                </label>
                <label>
                  <input
                    type="radio"
                    name="Durum"
                    value="false"
                    checked={isDurum === false}
                    onChange={() => setIsDurum(false)}
                  />{" "}
                  Pasif
                </label>
              </div>
            </div>

            <button
              type="submit"
              className={`bg-green-color text-white-color px-4 py-2 rounded-md ${
                isFormSubmitted ? "" : "bg-opacity-50 cursor-not-allowed"
              }`}
              disabled={!isFormSubmitted}
            >
              Güncelle
            </button>
          </form>
        </div>
      </div>
    )
  );
};

export default UpdateMarkaPopup;

// işlem durum tab eklenicek
