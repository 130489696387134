import React, { useState, useEffect } from "react";
import { Header, MesajModalWithIcon } from "./ui/generalComponents";
import AddServisPopup from "./ui/serviceComponents/AddServisPopup";
import ServisListItem from "./ui/serviceComponents/ServisListItem";
import { getServisler } from "services/api/servisApi/api";
import UpdateServisPopup from "./ui/serviceComponents/UpdateServisPopup";
import PopupFilterServis from "./ui/serviceComponents/PopupFilterServis";
import PdfPopup from "./ui/serviceComponents/PdfPopup";
import { AiOutlineInfoCircle } from "react-icons/ai";
import RaporPopup from "./ui/serviceComponents/RaporPopup";

function ServiceSection() {
  const [showPopup, setShowPopup] = useState(false);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [servisList, setServisList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageNumber, setTotalPageNumber] = useState(null);
  const [currentServis, setCurrentServis] = useState(null);
  const [pdfCurrentServis, setPdfCurrentServis] = useState(null);
  const [filters, setFilters] = useState({
    durum: null,
    cariId: null,
    firmaCihazId: null,
    markaId: null,
    modelId: null,
    islemDurum: null,
    baslangicTarih: null,
    bitisTarih: null,
  });
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const [pdfPopup, setPdfPopup] = useState(false);
  const [filterKey, setFilterKey] = useState(Date.now());
  const [raporPopup, setRaporPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const togglePdfPopup = () => {
    setPdfPopup(!pdfPopup);
  };

  const toggleRaporPopup = () => {
    setRaporPopup(!raporPopup);
  };

  const toggleUpdatePopup = () => {
    setShowUpdatePopup(!showUpdatePopup);
  };

  const handleUpdateClick = (servis) => {
    setCurrentServis(servis);
    toggleUpdatePopup();
  };

  const handlePdfClick = (servis) => {
    setPdfCurrentServis(servis);
    togglePdfPopup();
  };

  const handleRaporClick = () => {
    console.log("Deneme Deneme");
    toggleRaporPopup();
  };

  const handleUpdateSuccess = () => {
    setRefreshTrigger((prev) => !prev);
  };

  useEffect(() => {
    getServislerProcess();
  }, [pageNumber, showPopup, showUpdatePopup, filters, refreshTrigger]);

  const plusPageProcess = async () => {
    if (pageNumber < totalPageNumber) {
      setPageNumber(pageNumber + 1);
    }
  };

  const minusPageProcess = async () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleFilter = (filterValues) => {
    setFilters(filterValues);
    console.log(filterValues);
  };

  const getServislerProcess = async () => {
    try {
      const {
        durum,
        cariId,
        firmaCihazId,
        markaId,
        modelId,
        islemDurum,
        baslangicTarih,
        bitisTarih,
      } = filters;
      const getServislerApi = await getServisler(
        durum,
        pageNumber,
        10,
        cariId,
        firmaCihazId,
        markaId,
        modelId,
        islemDurum,
        baslangicTarih,
        bitisTarih
      );
      if (getServislerApi.success) {
        setServisList(getServislerApi.data.data);
        //console.log(getServislerApi.data.TotalPage);
        setTotalPageNumber(getServislerApi.data.TotalPage);
      } else {
        console.log(
          "ServiceSection -> getServislerProcess -> Servisler Cekilirken Hata: ",
          getServislerApi.basicAciklama
        );
      }
    } catch (error) {
      console.log("ServiceSection -> getServislerProcess -> Error:", error);
    }
  };

  const clearFilter = () => {
    console.log("Deneme Deneme");
    setFilters({
      durum: null,
      cariId: null,
      firmaCihazId: null,
      markaId: null,
      modelId: null,
      islemDurum: null,
    });
    setFilterKey(Date.now());
  };

  return (
    <div className="w-full h-full px-2 overflow-auto">
      <Header
        onAddClick={togglePopup}
        title={"Servisler Listesi"}
        pageNumber={pageNumber}
        totalPageNumber={totalPageNumber}
        plusPage={plusPageProcess}
        minusPage={minusPageProcess}
        onFilterClick={() => {}}
        isFilter={false}
        addTitle={"Servis Ekle"}
        isRapor={true}
        onRaporClick={handleRaporClick}
      />
      <PopupFilterServis
        onFilter={handleFilter}
        clearFilter={clearFilter}
        key={filterKey}
      />

      {servisList.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-full w-full">
          <MesajModalWithIcon
            Icon={AiOutlineInfoCircle}
            message="Seçtiğiniz filtreleme kriterlerinde servis kaydı bulunamadı."
          />
        </div>
      ) : (
        <div className="flex flex-col">
          {servisList.map((servis) => (
            <ServisListItem
              key={servis.id}
              servis={servis}
              handleUpdatePopup={handleUpdateClick}
              onPdfClick={handlePdfClick}
            />
          ))}
        </div>
      )}

      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <AddServisPopup onClose={togglePopup} />
        </div>
      )}

      {showUpdatePopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <UpdateServisPopup
            onClose={toggleUpdatePopup}
            currentServis={currentServis}
            onUpdate={handleUpdateSuccess}
          />
        </div>
      )}

      {pdfPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <PdfPopup onClose={togglePdfPopup} servis={pdfCurrentServis} />
        </div>
      )}

      {raporPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <RaporPopup onClose={toggleRaporPopup} servisList={servisList} />
        </div>
      )}
    </div>
  );
}

export default ServiceSection;
