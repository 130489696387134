import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa"; // react-icons'dan FaTimes ikonunu içe aktarın
import { addCihazTipiSchema } from "services/helpers/validations";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateCihazTip } from "services/api/yonetimApi/api";

const UpdateCihazTipPopup = ({ isOpen, onClose, currentCihazTip }) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isDurum, setIsDurum] = useState(currentCihazTip.Durum);

  const formik = useFormik({
    initialValues: {
      CihazTipAdi: currentCihazTip.CihazTipAdi,
    },
    onSubmit: async (values, bag) => {
      bag.resetForm();
      const cihazTipAdiSubmit = values.CihazTipAdi;
      updateCihazTipProcess(cihazTipAdiSubmit);
    },
    validationSchema: addCihazTipiSchema,
  });

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    formik;

  useEffect(() => {
    validateForm();
  }, [values, errors]);

  const validateForm = () => {
    if (values.CihazTipAdi && !errors.CihazTipAdi) {
      setIsFormSubmitted(true);
    } else {
      setIsFormSubmitted(false);
    }
  };

  const updateCihazTipProcess = async (cihazTipAdi) => {
    try {
      const updateCihazTipApi = await updateCihazTip(
        currentCihazTip.CihazTipId,
        cihazTipAdi,
        isDurum
      );
      if (updateCihazTipApi.succes) {
        console.log(
          "UpdateCihazTipPopup -> updateCihazTipProcess -> Cihaz Tipi Güncellendi",
          updateCihazTipApi.basicAciklama
        );
        toast.success(updateCihazTipApi.basicAciklama);
        onClose();
      } else {
        console.log(
          "UpdateCihazTipPopup -> updateCihazTipProcess -> Cihaz Tipi Güncellerken Hata",
          updateCihazTipApi.basicAciklama
        );
        toast.error(updateCihazTipApi.basicAciklama);
      }
    } catch (error) {
      console.log(
        "UpdateCihazTipPopup -> updateCihazTipProcess -> Error: ",
        error
      );
    }
  };

  return (
    isOpen && (
      <div className="fixed inset-0 bg-black-color bg-opacity-50 flex justify-center items-center">
        <div className="bg-white-color p-8 rounded-lg w-2/4 h-2/6 border-2 border-primary-brand-color overflow-auto">
          <div className="flex justify-between">
            <h3 className="text-primary-brand-color font-serif font-bold text-lg">
              Cihaz Tip Ekle
            </h3>
            <button onClick={onClose} className="focus:outline-none">
              <FaTimes className="text-primary-brand-color" />{" "}
              {/* Kapatma butonu için FaTimes ikonunu kullanın */}
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1"
                htmlFor="CihazTipAdi"
              >
                Cihaz Tip Adı
              </label>
              <input
                type="text"
                id="CihazTipAdi"
                name="CihazTipAdi"
                value={values.CihazTipAdi}
                onChange={(e) => {
                  handleChange(e);
                  validateForm();
                }}
                onBlur={handleBlur}
                className="w-full border-2 border-gray-200 p-1 rounded-md"
              />
              {errors.CihazTipAdi && touched.CihazTipAdi && (
                <div>
                  <p className="font-semibold text-xs text-red-color">
                    {errors.CihazTipAdi}
                  </p>
                </div>
              )}
            </div>

            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1"
                htmlFor="Durum"
              >
                Durum
              </label>
              <div>
                <label style={{ marginRight: "10px" }}>
                  <input
                    type="radio"
                    name="Durum"
                    value="true"
                    checked={isDurum === true}
                    onChange={() => {
                      setIsDurum(true);
                      validateForm();
                    }}
                  />{" "}
                  Aktif
                </label>
                <label>
                  <input
                    type="radio"
                    name="Durum"
                    value="false"
                    checked={isDurum === false}
                    onChange={() => {
                      setIsDurum(false);
                      validateForm();
                    }}
                  />{" "}
                  Pasif
                </label>
              </div>
            </div>

            <button
              type="submit"
              className={`bg-green-color text-white-color px-4 py-2 rounded-md ${
                isFormSubmitted ? "" : "bg-opacity-50 cursor-not-allowed"
              }`}
              disabled={!isFormSubmitted}
            >
              Güncelle
            </button>
          </form>
        </div>
      </div>
    )
  );
};

export default UpdateCihazTipPopup;
