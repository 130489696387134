import React, { useEffect, useState } from "react";
import Select from "react-select";

function SehirSelect({ options, onChange, defaultValue, addOperation }) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isDefaultValue, setIsDefaultValue] = useState(false);

  const handleSehirChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onChange(selectedOption);
  };

  useEffect(() => {
    // if (defaultValue != null) {
    //   onChange(defaultValue);
    // }
  }, [defaultValue]);

  // react-select için seçeneklerin formatını hazırlama
  const sehirOptions = options.map((sehir) => ({
    label: sehir.SehirAdi,
    value: sehir.SehirId,
  }));

  if (defaultValue != undefined) {
    if (addOperation == true) {
      defaultValue = sehirOptions.find((x) => x.value == defaultValue);
    } else {
      defaultValue = sehirOptions.find((x) => x.value == defaultValue.SehirId);
    }

    // handleSehirChange(defaultValue);
  }

  return (
    <>
      {addOperation == false ? (
        <div className="flex flex-row w-full justify-center items-center">
          <Select
            className="basic-single w-full"
            classNamePrefix="select"
            onChange={handleSehirChange}
            options={sehirOptions}
            placeholder="Şehir Seçin..."
            defaultValue={defaultValue != undefined && defaultValue}
          />
        </div>
      ) : (
        <div className="flex flex-row w-full justify-center items-center">
          <Select
            className="basic-single w-full"
            classNamePrefix="select"
            onChange={handleSehirChange}
            options={sehirOptions}
            placeholder="Şehirler Seçin..."
            value={defaultValue != undefined ? defaultValue : selectedOption}
          />
        </div>
      )}
    </>
  );
}

export default SehirSelect;
