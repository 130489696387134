import React, { useEffect, useState } from "react";
import Select from "react-select";

function KullaniciTipSelect({ options, value, onChange, defaultValue }) {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleCariChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onChange(selectedOption);
  };

  defaultValue = options.find((x) => x.value == defaultValue);

  return (
    <div className="flex flex-row w-full justify-center items-center">
      <Select
        className="basic-single w-full"
        classNamePrefix="select"
        onChange={handleCariChange}
        options={options}
        placeholder="Rol Seçin!"
        defaultValue={defaultValue != null ? defaultValue : null}
      />
    </div>
  );
}

export default KullaniciTipSelect;
