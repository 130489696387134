import React, { useEffect, useState } from "react";
import Select from "react-select";
import { IconButton } from "../generalComponents";
import { RiAddLine } from "react-icons/ri";

function ParcaSelect({
  options,
  onChange,
  defaultValue,
  onClickButton,
  filterOperation = true,
  addOperation,
  isDisabled,
}) {
  const [selectedOption, setSelectedOption] = useState(null);

  // react-select'in onChange olayı
  const handleParcaChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onChange(selectedOption); // Seçilen parça değerini üst bileşene iletmek için callback'i çağır
  };

  // react-select için seçeneklerin formatını hazırlama
  const parcaOptions = options.map((parca) => ({
    value: parca.ParcaId,
    label: parca.ParcaAdi,
  }));

  // defaultValue değerini kontrol ederek doğru formatta gösterim yapma
  let selectedDefaultValue = null;
  if (defaultValue !== undefined && defaultValue !== null) {
    selectedDefaultValue = addOperation
      ? parcaOptions.find((x) => x.value === defaultValue)
      : parcaOptions.find((x) => x.value === defaultValue.ParcaId);
  }

  return (
    <div className="flex flex-row w-full justify-center items-center">
      <Select
        className="basic-single w-full"
        classNamePrefix="select"
        onChange={handleParcaChange}
        options={parcaOptions}
        placeholder="Parça Seçin..."
        value={selectedOption || selectedDefaultValue}
        //isDisabled={isDisabled}
      />
      {!filterOperation && (
        <IconButton
          Icon={RiAddLine}
          bgColor={"primary-brand-color"}
          iconColor={"white-color"}
          onPress={onClickButton}
        />
      )}
    </div>
  );
}

export default ParcaSelect;
