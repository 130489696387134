import React, { useEffect, useState } from "react";
import Select from "react-select";
import { getSube } from "services/api/cariApi/api";

function SubeSelect({
  options,
  onChange,
  defaultValue,
  addOperation,
  selectedCariId,
  disabled = false,
}) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [subelerList, setSubelerList] = useState([]);

  useEffect(() => {
    if (selectedCariId) {
      setSelectedOption(null);
      getSubelerProcess(selectedCariId).then((subeler) => {
        setSubelerList(subeler);
      });
    } else {
      //console.log("Add Yeri");
      setSelectedOption(null);
      setSubelerList(options);
    }
  }, [selectedCariId, defaultValue, options]);

  useEffect(() => {
    if (defaultValue && subelerList.length > 0) {
      const defaultSube = subelerList.find(
        (sube) => sube.SubeId === defaultValue.SubeId
      );
      if (defaultSube != undefined) {
        setSelectedOption({
          value: defaultSube.SubeId,
          label: defaultSube.SubeAdi,
        });
      }
    }
  }, [defaultValue, subelerList]);

  const transformToOption = (data) => {
    //console.log(data);
    return data.map((item) => ({
      value: item.SubeId,
      label: item.SubeAdi,
    }));
  };

  const subeOptions = transformToOption(options);
  const subelerListOptions = transformToOption(subelerList);

  const getSubelerProcess = async (cariId) => {
    try {
      const getSubelerApi = await getSube(cariId, 1, 1000);
      return getSubelerApi.data;
    } catch (error) {
      console.log("PopupAddCari -> getSubelerProcess -> Error: ", error);
    }
  };

  const handleSubeChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onChange(selectedOption);
  };

  return (
    <div className="flex flex-row w-full justify-center items-center">
      <Select
        className="basic-single w-full"
        classNamePrefix="select"
        onChange={handleSubeChange}
        options={addOperation ? subeOptions : subelerListOptions}
        placeholder={addOperation ? "Lokasyon Seçin..." : "Lokasyon Seçin..."}
        value={selectedOption}
        isDisabled={disabled}
      />
    </div>
  );
}

export default SubeSelect;
