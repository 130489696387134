import React, { useState, useEffect } from "react";
import { Header } from "../generalComponents";
import AddParcaPopup from "./AddParcaPopup";
import { GetParcalar } from "services/api/yonetimApi/api";
import TableItemParca from "./TableItemParca";
import UpdateParcaPopup from "./UpdateParcaPopup";

function AddParcaView() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [buttonPosition, setButtonPosition] = useState({ x: 0, y: 0 });
  const [parcaList, setParcaList] = useState([]);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [currentParca, setCurrentParca] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageNumber, setTotalPageNumber] = useState(null);

  useEffect(() => {
    getParcalarProcess();
  }, [pageNumber, isPopupOpen, showUpdatePopup]);

  const plusPageProcess = async () => {
    if (pageNumber < totalPageNumber) {
      setPageNumber(pageNumber + 1);
    }
  };

  const minusPageProcess = async () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleAddClick = (e) => {
    // Butonun konumunu alın
    const buttonRect = e.target.getBoundingClientRect();
    setButtonPosition({ x: buttonRect.left, y: buttonRect.bottom });

    // Popup'u açın
    setIsPopupOpen(true);
  };
  const handleClose = () => {
    // Popup'u kapatın
    setIsPopupOpen(false);
  };

  const handleUpdateClose = () => {
    setShowUpdatePopup(false);
  };

  const toggleUpdatePopup = (e, parca) => {
    const buttonRect = e.target.getBoundingClientRect();
    setButtonPosition({ x: buttonRect.right, y: buttonRect.top });

    setCurrentParca(parca); // Seçilen cari bilgisini state'e kaydediyoruz
    setShowUpdatePopup(!showUpdatePopup);
  };

  const getParcalarProcess = async () => {
    try {
      const getParcalarApi = await GetParcalar(pageNumber, 10, null);
      if (getParcalarApi.succes) {
        // console.log(
        //   "AddParcaView -> getParcalarProcess -> Veriler Başarılı Çekildi"
        // );
        setParcaList(getParcalarApi.data.data);
        setTotalPageNumber(getParcalarApi.data.TotalPage);
      } else {
        console.log(
          "AddParcaView -> getParcalarProcess -> Parcalar Çekilirken bİR HATA oluştu",
          getParcalarApi.basicAciklama
        );
      }
    } catch (error) {
      console.log("AddParcaView -> getParcalarProcess -> Error: ", error);
    }
  };

  return (
    <div className="h-full w-full">
      <Header
        title={"Parçalar Listesi"}
        onAddClick={handleAddClick}
        pageNumber={pageNumber}
        totalPageNumber={totalPageNumber}
        plusPage={plusPageProcess}
        minusPage={minusPageProcess}
        isFilter={false}
        addTitle={"Parça Ekle"}
      />
      <AddParcaPopup
        isOpen={isPopupOpen}
        onClose={handleClose}
        buttonPosition={buttonPosition}
      />
      <div className="mt-4">
        {parcaList.map((parca) => (
          <TableItemParca
            key={parca.ParcaId}
            parca={parca}
            onUpdateClick={(e, parca) => {
              toggleUpdatePopup(e, parca);
            }}
          />
        ))}
      </div>
      {showUpdatePopup && (
        <div className="fixed inset-0 bg-black-color bg-opacity-50 flex justify-center items-center">
          <UpdateParcaPopup
            isOpen={showUpdatePopup}
            onClose={handleUpdateClose}
            currentParca={currentParca}
            buttonPosition={buttonPosition}
          />
        </div>
      )}
    </div>
  );
}

export default AddParcaView;
