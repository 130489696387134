import React, { useEffect, useState } from "react";
import JustHeader from "./ui/generalComponents/JustHeader";
import AddKullaniciPopup from "./ui/kullanicilarComponents/AddKullaniciPopup";
import { getKullanici } from "services/api/kullaniciApi/api";
import KullaniciListItem from "./ui/kullanicilarComponents/KullaniciListItem";
import UpdateKullaniciPopup from "./ui/kullanicilarComponents/UpdateKullaniciPopup";

export default function UsersSection() {
  const [showPopup, setShowPopup] = useState(false);
  const [kullanicilarList, setKullanicilarList] = useState([]);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [currentKullanici, setCurrentKullanici] = useState(null);

  useEffect(() => {
    getKullaniciProcess();
  }, [showPopup, showUpdatePopup]);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const toggleUpdatePopup = () => {
    //setCurrentCihaz(cihaz); // Seçilen cari bilgisini state'e kaydediyoruz
    setShowUpdatePopup(!showUpdatePopup);
  };

  const getKullaniciProcess = async () => {
    try {
      const getKullaniciApi = await getKullanici(null);
      if (getKullaniciApi.succes) {
        // console.log(
        //   "UsersSection -> getKullaniicProcess -> Kullanicilar Başarılı Şekilde Getirildi: ",
        //   getKullaniciApi.basicAciklama
        // );
        //console.log(getKullaniciApi.data);
        setKullanicilarList(getKullaniciApi.data);
      } else {
        console.log(
          "UsersSection -> getKullaniicProcess -> Kullanicilar Getirilirken Hata: ",
          getKullaniciApi.basicAciklama
        );
      }
    } catch (err) {
      console.log("UsersSection -> getKullaniciProcess -> Error: ", err);
    }
  };

  const toggleHandleUpdateClick = (e, kullanici) => {
    console.log("Current Kullanici : ", kullanici);
    setCurrentKullanici(kullanici); // Seçilen cari bilgisini state'e kaydediyoruz
    setShowUpdatePopup(!showUpdatePopup);
  };

  return (
    <div className="w-full h-full px-2 overflow-auto">
      <JustHeader title={"Kullanıcılar Listesi"} onAddClick={togglePopup} />

      <div className="mt-4">
        {kullanicilarList.map((kullanici) => (
          <KullaniciListItem
            Kullanici={kullanici}
            onUpdateClick={toggleHandleUpdateClick}
          />
        ))}
      </div>

      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <AddKullaniciPopup onClose={togglePopup} />
        </div>
      )}

      {showUpdatePopup && (
        <div className="fixed inset-0 bg-black-color bg-opacity-50 flex justify-center items-center">
          <UpdateKullaniciPopup
            onClose={toggleUpdatePopup}
            currentKullanici={currentKullanici}
          />
        </div>
      )}
    </div>
  );
}
