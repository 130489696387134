import axios from "axios";
import {
  GET_CARI,
  ADD_CARI,
  DELETE_CARI,
  UPDATE_CARI,
  GET_CARI_SUBE,
  ADD_CARI_SUBE,
  DELETE_SUBE,
  UPDATE_SUBE,
  AKTIF_CARI,
  GET_CARI_BY_ID,
} from "services/helpers/contants";
import axiosApiInstance from "../axiosClient";

export const getCariById = async (cariId) => {
  try {
    const { data } = await axiosApiInstance.get(
      `${GET_CARI_BY_ID}?id=${cariId}`
    );

    if (data.BasicStatus === "Basarili") {
      return {
        success: true,
        basicAciklama: data.BasicAciklama,
        data: data,
      };
    } else {
      console.log(
        "cariApi -> api -> getCariById -> Veri çekilirken hata:",
        data.BasicAciklama
      );
      return {
        success: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("CariApi -> api -> getCariById -> Error: ", err);
  }
};

export const getCari = async (
  Durum = true,
  PageNumber,
  PageSize,
  Search,
  CariTipleri,
  OzelTuzel,
  SehirId,
  IlceId
) => {
  try {
    const { data } = await axiosApiInstance.post(`${GET_CARI}`, {
      Durum: Durum,
      PageNumber: PageNumber,
      PageSize: PageSize,
      Search: Search,
      CariTipleri: CariTipleri,
      OzelTuzel: OzelTuzel,
      SehirId: SehirId,
      IlceId: IlceId,
    });

    if (data.BasicStatus === "Basarili") {
      return {
        success: true,
        basicAciklama: data.BasicAciklama,
        data: data,
      };
    } else {
      console.log(
        "cariApi -> api -> getCari -> Veri çekilirken hata:",
        data.BasicAciklama
      );
      return {
        success: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("cariApi -> api -> getCari -> Error:", err);
  }
};

export const getSube = async (cariId, PageNumber, PageSize) => {
  try {
    const { data } = await axiosApiInstance.post(`${GET_CARI_SUBE}`, {
      Durum: true,
      PageNumber: PageNumber,
      PageSize: PageSize,
      CariId: cariId,
    });
    if (data.BasicStatus === "Basarili") {
      return {
        success: true,
        basicAciklama: data.BasicAciklama,
        data: data.data,
      };
    } else {
      console.log(
        "cariApi -> api -> getCari -> Veri çekilirken hata:",
        data.BasicAciklama
      );
      return {
        success: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("cariApi -> api -> getCari -> Error:", err);
  }
};

export const deleteCari = async (cariId) => {
  try {
    const { data } = await axiosApiInstance.get(`${DELETE_CARI}?id=${cariId}`);
    if (data.BasicStatus === "Basarili") {
      return {
        succes: true,
        basicAciklama: data.BasicAciklama,
      };
    } else {
      return {
        succes: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("cariApi -> api -> deleteCari -> Error:", err);
  }
};

export const aktifCari = async (cariId) => {
  try {
    const { data } = await axiosApiInstance.get(`${AKTIF_CARI}?id=${cariId}`);
    if (data.BasicStatus === "Basarili") {
      return {
        succes: true,
        basicAciklama: data.BasicAciklama,
      };
    } else {
      return {
        succes: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("cariApi -> api -> aktifCari -> Error: ", err);
  }
};

export const deleteSube = async (subeId) => {
  try {
    const { data } = await axiosApiInstance.get(`${DELETE_SUBE}?id=${subeId}`);
    if (data.BasicStatus === "Basarili") {
      return {
        succes: true,
        basicAciklama: data.BasicAciklama,
      };
    } else {
      return {
        succes: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("cariApi -> api -> deleteSube -> Error: ", err);
  }
};

export const updateSube = async (
  subeId,
  cariId,
  subeAdi,
  vergiDairesi,
  vergiNo,
  sehirId,
  ilceId,
  adres,
  telefon,
  email,
  yetkili,
  yetkiliGsm
) => {
  try {
    const { data } = await axiosApiInstance.post(`${UPDATE_SUBE}`, {
      SubeId: subeId,
      CariId: cariId,
      SubeAdi: subeAdi,
      VergiDairesi: vergiDairesi,
      VergiNo: vergiNo,
      SehirId: sehirId,
      IlceId: ilceId,
      Adres: adres,
      Telefon: telefon,
      Email: email,
      Yetkili: yetkili,
      YetkiliGsm: yetkiliGsm,
    });
    if (data.BasicStatus === "Basarili") {
      return {
        success: true,
        basicAciklama: data.BasicAciklama,
      };
    } else {
      console.log("cariApi -> api -> updateSube -> Sube Güncellenirken Hata");
      return {
        success: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("CariApi -> api -> updateSube -> Error: ", err);
  }
};

export const updateCari = async (
  cariId,
  cariUnvan,
  yetkiliAdSoyadBir,
  yetkiliAdSoyadIki,
  yetkiliTelefonBir,
  yetkiliTelefonIki,
  yetkiliEmailBir,
  yetkiliEmailIki,
  cariTelefon,
  //cariOzelTuzel,
  cariTipi,
  vergiDairesi,
  //vergiNo,
  tcOrVergiNo,
  sehirId,
  ilceId,
  adres,
  loginDurum
) => {
  console.log("tcOrVergiNo: ", tcOrVergiNo);
  // let vergiNoOrTc = "";
  // if (cariOzelTuzel === "Ozel") {
  //   vergiNoOrTc = tc;
  // } else {
  //   vergiNoOrTc = vergiNo;
  // }

  // let ozelTuzelDurum = "";
  // if (cariOzelTuzel === "Özel") {
  //   ozelTuzelDurum = "Ozel";
  // } else if (cariOzelTuzel === "Tüzel") {
  //   ozelTuzelDurum = "Tuzel";
  // } else {
  //   ozelTuzelDurum = "Kamu";
  // }

  try {
    const { data } = await axiosApiInstance.post(`${UPDATE_CARI}`, {
      Durum: true,
      CariId: cariId,
      CariUnvan: cariUnvan,
      BirinciYetkiliAdiSoyadi: yetkiliAdSoyadBir,
      BirinciYetkiliEmail: yetkiliEmailBir,
      BirinciYetkiliGsm: yetkiliTelefonBir,
      IkinciYetkiliAdiSoyadi: yetkiliAdSoyadIki,
      IkinciYetkiliEmail: yetkiliEmailIki,
      IkinciYetkiliGsm: yetkiliTelefonIki,
      //CariOzelTuzel: ozelTuzelDurum,
      CariTipi: cariTipi,
      VergiDairesi: vergiDairesi,
      VergiNoOrTc: tcOrVergiNo,
      SehirId: sehirId,
      IlceId: ilceId,
      Adres: adres,
      Telefon: cariTelefon,
      LoginDurum: loginDurum,
    });
    //console.log(tc, vergiNo);
    if (data.BasicStatus === "Basarili") {
      return {
        success: true,
        basicAciklama: data.BasicAciklama,
      };
    } else {
      console.log("cariApi -> api -> updateCari -> Cari Güncellenirken Hata");
      return {
        success: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("cariApi -> api -> updateCari -> Error:", err);
  }
};

export const addSube = async (
  cariId,
  subeAdi,
  vergiDairesi,
  vergiNo,
  sehirId,
  ilceId,
  adres,
  telefon,
  email,
  yetkiliAd,
  yetkiliGsm
) => {
  console.log(cariId);
  const { data } = await axiosApiInstance.post(`${ADD_CARI_SUBE}`, {
    CariId: cariId,
    SubeAdi: subeAdi,
    VergiDairesi: vergiDairesi,
    vergiNo: vergiNo,
    SehirId: sehirId,
    IlceId: ilceId,
    Adres: adres,
    Telefon: telefon,
    Email: email,
    Yetkili: yetkiliAd,
    YetkiliGsm: yetkiliGsm,
  });
  if (data.BasicStatus === "Basarili") {
    return {
      success: true,
      basicAciklama: data.BasicAciklama,
    };
  } else {
    console.log("cariApi -> api -> addCari -> Cari Eklenirken Hata");
    return {
      success: false,
      basicAciklama: data.BasicAciklama,
    };
  }
};

export const addCari = async (
  cariUnvan,
  yetkiliAdSoyadBir,
  yetkiliAdSoyadIki,
  yetkiliTelefonBir,
  yetkiliTelefonIki,
  yetkiliEmailBir,
  yetkiliEmailIki,
  cariTelefon,
  //cariOzelTuzel,
  cariTipi,
  vergiDairesi,
  //vergiNo,
  tcOrVergiNo,
  sehirId,
  ilceId,
  adres,
  loginDurum
) => {
  // let vergiNoOrTc = "";
  // if (cariOzelTuzel === "Özel") {
  //   vergiNoOrTc = tc;
  // } else {
  //   vergiNoOrTc = vergiNo;
  // }

  // let ozelTuzelDurum = "";
  // if (cariOzelTuzel === "Özel") {
  //   ozelTuzelDurum = "Ozel";
  // } else if (cariOzelTuzel === "Tüzel") {
  //   ozelTuzelDurum = "Tuzel";
  // } else {
  //   ozelTuzelDurum = "Kamu";
  // }

  try {
    const { data } = await axiosApiInstance.post(`${ADD_CARI}`, {
      Durum: true,
      CariUnvan: cariUnvan,
      BirinciYetkiliAdiSoyadi: yetkiliAdSoyadBir,
      BirinciYetkiliEmail: yetkiliEmailBir,
      BirinciYetkiliGsm: yetkiliTelefonBir,
      IkinciYetkiliAdiSoyadi: yetkiliAdSoyadIki,
      IkinciYetkiliEmail: yetkiliEmailIki,
      IkinciYetkiliGsm: yetkiliTelefonIki,
      //CariOzelTuzel: ozelTuzelDurum,
      CariTipi: cariTipi,
      VergiDairesi: vergiDairesi,
      VergiNoOrTc: tcOrVergiNo,
      SehirId: sehirId,
      IlceId: ilceId,
      Adres: adres,
      Telefon: cariTelefon,
      LoginDurum: loginDurum,
    });

    if (data.BasicStatus === "Basarili") {
      return {
        success: true,
        basicAciklama: data.BasicAciklama,
      };
    } else {
      console.log("cariApi -> api -> addCari -> Cari Eklenirken Hata");
      return {
        succes: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("cariApi -> api -> addCari -> Error:", err);
  }
};
