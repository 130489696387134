import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa"; // react-icons'dan FaTimes ikonunu içe aktarın
import {
  addCihazTipiSchema,
  addParcaSchema,
} from "services/helpers/validations";
import { useFormik } from "formik";
import { addCihazTip } from "services/api/yonetimApi/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddCihazTipiPopup = ({
  isOpen,
  onClose,
  buttonPosition,
  options,
  defaultValue,
}) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [selectedCariId, setSelectedCariId] = useState(null);

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    useFormik({
      initialValues: {
        CihazTipAdi: "",
      },
      onSubmit: async (values, bag) => {
        bag.resetForm();
        const cihazTipAdiSubmit = values.CihazTipAdi;
        addCihazTipProcess(cihazTipAdiSubmit);
      },
      validationSchema: addCihazTipiSchema,
    });

  useEffect(() => {
    //getCariler();
    //console.log(options);
  }, []);

  useEffect(() => {
    if (Object.keys(touched).length > 0 && Object.keys(errors).length === 0) {
      setIsFormSubmitted(true);
    } else {
      setIsFormSubmitted(false);
    }
  }, [touched, errors]);

  const addCihazTipProcess = async (cihazTipAdi) => {
    try {
      const addCihazTipApi = await addCihazTip(cihazTipAdi);
      if (addCihazTipApi.succes) {
        console.log(
          "addCihazTipiPopup -> addCihazTipProcess -> cihaz tipi eklendi",
          addCihazTipApi.basicAciklama
        );
        toast.success(addCihazTipApi.basicAciklama);
        onClose();
      } else {
        console.log(
          "addCihazTipiPopup -> addCihazTipProcess -> Cihaz Tipi Eklenirken Hata: ",
          addCihazTipApi.basicAciklama
        );
        toast.error(addCihazTipApi.basicAciklama);
      }
    } catch (error) {
      console.log("AddCihazTipPopup -> addCihazTipProcess -> Error: ", error);
    }
  };

  return (
    isOpen && (
      <div className="fixed inset-0 bg-black-color bg-opacity-50 flex justify-center items-center">
        <div className="bg-white-color p-8 rounded-lg w-2/4 h-2/6 border-2 border-primary-brand-color overflow-auto">
          <div className="flex justify-between">
            <h3 className="text-primary-brand-color font-serif font-bold text-lg">
              Cihaz Tip Ekle
            </h3>
            <button onClick={onClose} className="focus:outline-none">
              <FaTimes className="text-primary-brand-color" />{" "}
              {/* Kapatma butonu için FaTimes ikonunu kullanın */}
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1"
                htmlFor="CihazTipAdi"
              >
                Cihaz Tip Adı
              </label>
              <input
                type="text"
                id="CihazTipAdi"
                name="CihazTipAdi"
                value={values.CihazTipAdi}
                onChange={handleChange}
                onBlur={handleBlur}
                className="w-full border-2 border-gray-200 p-1 rounded-md"
              />
              {errors.CihazTipAdi && touched.CihazTipAdi && (
                <div>
                  <p className="font-semibold text-xs text-red-color">
                    {errors.CihazTipAdi}
                  </p>
                </div>
              )}
            </div>

            <button
              type="submit"
              className={`bg-green-color text-white-color px-4 py-2 rounded-md ${
                isFormSubmitted ? "" : "bg-opacity-50 cursor-not-allowed"
              }`}
              disabled={!isFormSubmitted}
            >
              Kaydet
            </button>
          </form>
        </div>
      </div>
    )
  );
};

export default AddCihazTipiPopup;
