import React, { useEffect } from "react";

import { AiTwotoneDelete } from "react-icons/ai";
import { IoClose } from "react-icons/io5";

import { useNavigate } from "react-router-dom";
import { IconButton } from "../generalComponents";

function TabItemCihaz({ cihaz, onUpdateClick, addOperation, onDeleteClick }) {
  useEffect(() => {
    console.log(cihaz);
  }, []);

  return (
    <div className="p-4 mb-4 rounded-md flex flex-row items-center justify-between border-2 border-primary-brand-color cursor-pointer">
      <div className="flex flex-row items-center">
        <div className="flex flex-row items-center">
          <div className="flex flex-col">
            <h2 className="text-xl font-serif font-semibold ">
              {/* {addOperation ? cihaz.FirmaCihazAdi : cihaz.FirmaCihaz.CihazAdi} */}
              {cihaz.FirmaCihazAdi}
            </h2>
            <ul>
              <p className="font-bold">Açıklama:</p>
              {cihaz.ServisIslemleri.map((islem, islemIndex) => (
                <li className=" list-disc mx-4" key={islemIndex}>
                  {islem.MusteriAciklamasi}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <IconButton
          onPress={() => onDeleteClick(cihaz)}
          bgColor="red-color"
          Icon={IoClose}
          iconColor="white-color"
          textColor="white-color"
        />
      </div>
    </div>
  );
}

export default TabItemCihaz;
