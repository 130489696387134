import React, { useEffect, useState } from "react";
import { Header } from "../generalComponents"; // Header bileşenini içe aktarın
import AddMarkaPopup from "./AddMarkaPopup";
import TableItem from "./TableItem";
import { getMarkalar } from "services/api/yonetimApi/api";
import UpdateMarkaPopup from "./UpdateMarkaPopup";
import SearchBox from "../generalComponents/SearchBox";

function AddMarkaView() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [buttonPosition, setButtonPosition] = useState({ x: 0, y: 0 });
  const [markalarList, setMarkalarList] = useState([]);
  const [currentMarka, setCurrentMarka] = useState();
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageNumber, setTotalPageNumber] = useState(null);
  const [filters, setFilters] = useState({
    search: null,
  });
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    getMarkalarProcess();
  }, [pageNumber, isPopupOpen, showUpdatePopup, filters]);

  const plusPageProcess = async () => {
    if (pageNumber < totalPageNumber) {
      setPageNumber(pageNumber + 1);
    }
  };

  const minusPageProcess = async () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleAddClick = (e) => {
    // Butonun konumunu alın
    const buttonRect = e.target.getBoundingClientRect();
    setButtonPosition({ x: buttonRect.left, y: buttonRect.bottom });

    // Popup'u açın
    setIsPopupOpen(true);
  };

  const handleClose = () => {
    // Popup'u kapatın
    setIsPopupOpen(false);
  };

  const handleUpdateClose = () => {
    setShowUpdatePopup(false);
  };

  const toggleUpdatePopup = (e, marka) => {
    const buttonRect = e.target.getBoundingClientRect();
    setButtonPosition({ x: buttonRect.right, y: buttonRect.top });

    setCurrentMarka(marka); // Seçilen cari bilgisini state'e kaydediyoruz
    setShowUpdatePopup(!showUpdatePopup);
  };

  const getMarkalarProcess = async () => {
    const { search } = filters;
    try {
      const getMarkalarApi = await getMarkalar(pageNumber, 10, search);
      if (getMarkalarApi.succes) {
        // console.log(
        //   "AddMarkaView -> getMarkalarProcess -> Markalar Başarılı Bir Şekilde Çekildi",
        //   getMarkalarApi.data
        // );
        setMarkalarList(getMarkalarApi.data.data);
        //console.log(getMarkalarApi.data);
        setTotalPageNumber(getMarkalarApi.data.TotalPage);
      } else {
        console.log(
          "AddMarkaView -> getMarkalarProcess -> Markalar Çekilirken Hata",
          getMarkalarApi.basicAciklama
        );
      }
    } catch (error) {
      console.log("AddMarkaView -> getMarkalarProcess -> Error:", error);
    }
  };

  const clearFilter = () => {
    console.log("Deneme Deneme");
    setFilters({
      search: null,
    });
    setSearchTerm("");
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
    if (term.length > 3) {
      setSearchTerm(term);
      console.log(term);
      setFilters((prevFilters) => ({
        ...prevFilters,
        search: term,
      }));
    } else {
      setSearchTerm(term);
      console.log("Term length is less than or equal to 4. Clearing search...");
      setFilters((prevFilters) => ({
        ...prevFilters,
        search: null,
      }));
    }
  };

  return (
    <div className=" w-full - h-full">
      <Header
        title={"Marka Listesi"}
        onAddClick={handleAddClick}
        pageNumber={pageNumber}
        totalPageNumber={totalPageNumber}
        plusPage={plusPageProcess}
        minusPage={minusPageProcess}
        addTitle={"Marka Ekle"}
      />
      <div className="my-4">
        <SearchBox
          searchTerm={searchTerm}
          onSearch={handleSearch}
          clearFilter={clearFilter}
          placeholder={"Marka Ad"}
        />
      </div>
      <AddMarkaPopup
        isOpen={isPopupOpen}
        onClose={handleClose}
        buttonPosition={buttonPosition}
      />
      <div className="mt-4">
        {markalarList.map((marka) => (
          <TableItem
            key={marka.MarkaId}
            marka={marka}
            onUpdateClick={(e, marka) => {
              toggleUpdatePopup(e, marka);
            }}
          />
        ))}
      </div>
      {showUpdatePopup && (
        <div className="fixed inset-0 bg-black-color bg-opacity-50 flex justify-center items-center">
          <UpdateMarkaPopup
            isOpen={showUpdatePopup}
            onClose={handleUpdateClose}
            currentMarka={currentMarka}
            buttonPosition={buttonPosition}
          />
        </div>
      )}
    </div>
  );
}

export default AddMarkaView;
