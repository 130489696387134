import React, { useEffect, useState } from "react";
import Select from "react-select";
import { AddButton, IconButton } from "../generalComponents";
import { RiAddLine } from "react-icons/ri";

function CariSelect({
  options,
  onChange,
  defaultValue,
  onClickButton,
  addOperation,
  disabled = false,
}) {
  const [selectedOption, setSelectedOption] = useState(defaultValue);
  const [isDefaultValue, setIsDefaultValue] = useState(false);

  const handleCariChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onChange(selectedOption);
  };

  useEffect(() => {
    // console.log(options, defaultValue);
  }, [defaultValue]);

  const cariOptions = options.map((cari) => ({
    value: cari.CariId,
    label: cari.CariUnvan,
  }));

  // const handleAddButtonClick = (e) => {
  //   e.preventDefault(); // Form gönderme davranışını durdur
  //   onClickButton(); // IconButtona tıklandığında çağrılacak işlevi çağır
  // };

  if (defaultValue != undefined) {
    defaultValue = cariOptions.find((x) => x.value == defaultValue.CariId);
    // handleSehirChange(defaultValue);
  }

  return (
    <>
      {addOperation == false ? (
        <div className="flex flex-row w-full justify-center items-center">
          <Select
            className="basic-single w-full"
            classNamePrefix="select"
            defaultValue={defaultValue != undefined && defaultValue}
            onChange={handleCariChange}
            options={cariOptions}
            isDisabled={disabled}
          />
        </div>
      ) : (
        <div className="flex flex-row w-full justify-center items-center">
          <Select
            className="basic-single w-full"
            classNamePrefix="select"
            onChange={handleCariChange}
            options={cariOptions}
            placeholder="Cari Seçin..."
            value={selectedOption}
            isDisabled={disabled}
          />
        </div>
      )}
    </>
  );
}

export default CariSelect;
