import React, { useEffect } from "react";

import { IoClose, IoCheckmark } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { IconButton } from "../generalComponents";

function TabItemGecmisCihaz({
  cihaz,
  onUpdateClick,
  addOperation,
  cihazSilClick,
  cihazAktifClick,
  cihazUpdateClick,
}) {
  useEffect(() => {
    //console.log(cihaz.Durum);
  }, []);

  return (
    <div
      className={`p-4 mb-4 rounded-md flex items-center justify-between border-2 ${
        cihaz.Durum ? "border-green-color" : "border-red-color"
      }`}
    >
      <div
        className="flex flex-col cursor-pointer"
        onClick={() => cihazUpdateClick(cihaz)}
      >
        <h2 className="text-xl font-serif font-semibold">
          {addOperation ? cihaz.FirmaCihazAdi : cihaz.FirmaCihaz.CihazAdi}
        </h2>
        <ul>
          <p className="font-bold">
            Müşteri Açıklaması - Yapılan İşlem - Servis Açıklaması
          </p>
          {cihaz.ServisIslemleri.map((islem, islemIndex) => (
            <li key={islemIndex}>
              {islem.MusteriAciklamasi} - {islem.YapilanIslem} -{" "}
              {islem.Aciklama}
            </li>
          ))}
        </ul>
      </div>
      <div className="flex justify-center items-center">
        {cihaz.Durum === true && (
          <IconButton
            onPress={() => cihazSilClick(cihaz)}
            bgColor="red-color"
            Icon={IoClose}
            iconColor="white-color"
            textColor="white-color"
          />
        )}
        {cihaz.Durum === false && (
          <IconButton
            onPress={() => cihazAktifClick(cihaz)}
            bgColor="primary-brand-color"
            Icon={IoCheckmark}
            iconColor="white-color"
            textColor="white-color"
          />
        )}
      </div>
    </div>
  );
}

export default TabItemGecmisCihaz;
