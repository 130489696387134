import React, { useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { getSehirler, getCihazTipleri } from "services/api/yonetimApi/api";
import { getCari, getSube } from "services/api/cariApi/api";
import { getCihaz } from "services/api/cihazApi/api";
import { getMarkalar, getModeller } from "services/api/yonetimApi/api";
import { SehirSelect, IlceSelect, CariTipSelect } from "../generalComponents";
import {
  aktifDurumData,
  musteriTipData,
  cariStatusData,
  islemDurumData,
} from "data/data";
import DurumSelect from "../generalComponents/DurumSelect";
import StatusSelect from "../generalComponents/StatusSelect";
import CariSelect from "../deviceComponents/CariSelect";
import SubeSelect from "./SubeSelect";
import CihazTipSelect from "../deviceComponents/CihazTipSelect";
import CihazSelect from "../serviceComponents/CihazSelect";
import MarkaSelect from "../deviceComponents/MarkaSelect";
import ModelSelect from "../deviceComponents/ModelSelect";

function PopupFilterCihaz({ onClose, onFilter, searchTerm }) {
  const [cariList, setCariList] = useState([]);
  const [subelerList, setSubelerList] = useState([]);
  const [cihazTipList, setCihazTipList] = useState([]);
  const [markaList, setMarkaList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [selectedCariId, setSelectedCariId] = useState(null);
  const [selectedSubeId, setSelectedSubeId] = useState(null);
  const [selectedCihazId, setSelectedCihazId] = useState(null);
  const [selectedMarkaId, setSelectedMarkaId] = useState(null);
  const [selectedModelId, setSelectedModelId] = useState(null);
  const [selectedIslemDurum, setSelectedIslemDurum] = useState(null);
  const [selectedDurum, setSelectedDurum] = useState(null);

  useEffect(() => {
    getCarilerProcess();
    getMarkalarProcess();
    getCihazTipProcess();
  }, []);

  const getCarilerProcess = async () => {
    try {
      const getCarilerApi = await getCari(
        true,
        1,
        1000,
        null,
        null,
        null,
        null
      );
      //console.log(getSehirlerApi.data);
      setCariList(getCarilerApi.data.data);
    } catch (error) {
      console.log("AddServisPopup -> getCarilerProcess -> Error: ", error);
    }
  };

  const getCihazTipProcess = async () => {
    try {
      const getCihazTipApi = await getCihazTipleri(1, 1000, null);
      if (getCihazTipApi.succes) {
        // console.log(
        //   "PopupAddCihaz -> getCihazTipProcess -> Cihaz Tipleri Başarılı Çekildi",
        //   getCihazTipApi.basicAciklama
        // );
        setCihazTipList(getCihazTipApi.data.data);
      } else {
        console.log(
          "PopupAddCihaz -> getCihazTipProcess -> Cihaz Tipleri Çekilirken Hata:",
          getCihazTipApi.basicAciklama
        );
      }
    } catch (error) {
      console.log("PopupAddCihaz -> getCihazTipProcess -> Error: ", error);
    }
  };

  const getMarkalarProcess = async () => {
    try {
      const getMarkalarApi = await getMarkalar(1, 1000);
      if (getMarkalarApi.succes) {
        // console.log(
        //   "PopupAddCihaz -> getMarkaProcess -> Markalar Başarılı Şekilde Çekildi ",
        //   getMarkalarApi.basicAciklama
        // );
        setMarkaList(getMarkalarApi.data.data);
      } else {
        console.log(
          "PopupAddCihaz -> getMarkaProcess -> Markalar Çekilirken Hata  ",
          getMarkalarApi.basicAciklama
        );
      }
    } catch (error) {
      console.log("PopupAddCihaz -> getMarkaProcess -> Error: ", error);
    }
  };

  const getSubeProcess = async (cariId) => {
    try {
      const getSubelerApi = await getSube(cariId, 1, 1000);
      if (getSubelerApi.success) {
        console.log(
          "PopupAddCihaz -> getSubeProcess -> Şubeler Çekildi",
          getSubelerApi.basicAciklama
        );
        setSubelerList(getSubelerApi.data);
      } else {
        console.log(
          "PopupAddCihaz -> getSubeProcess -> Şubeler Çekilirken Hata",
          getSubelerApi.basicAciklama
        );
      }
    } catch (error) {
      console.log("PopupAddCihaz -> getSubeProcess -> Error: ", error);
    }
  };

  const getModelProcess = async (markaId) => {
    try {
      const getModelApi = await getModeller(markaId, 1, 1000);
      if (getModelApi.succes) {
        console.log(
          "PopupAddCihaz -> getModelProcess -> Modeller Başarılı Çekildi",
          getModelApi.basicAciklama
        );
        setModelList(getModelApi.data.data);
      } else {
        console.log(
          "PopupAddCihaz -> getModelProcess -> Model Çekilirken Hata:",
          getModelApi.basicAciklama
        );
      }
    } catch (error) {
      console.log("PopupAddCihaz -> getModelProcess -> Error: ", error);
    }
  };

  const handleCariChange = (cari) => {
    setSelectedCariId(cari.value);

    getSubeProcess(cari.value);
  };

  const handleSubeChange = (sube) => {
    setSelectedSubeId(sube.value);
  };

  const handleCihazTipChange = (cihaz) => {
    setSelectedCihazId(cihaz.value);
  };

  const handleMarkaChange = (marka) => {
    setSelectedMarkaId(marka.value);
    getModelProcess(marka.value);
  };

  const handleModelChange = (model) => {
    setSelectedModelId(model.value);
  };

  const handleCihazDurumChange = (durum) => {
    setSelectedDurum(durum.value);
  };

  const handleFilter = () => {
    onFilter({
      durum: selectedDurum,
      cariId: selectedCariId,
      subeId: selectedSubeId,
      markaId: selectedMarkaId,
      modelId: selectedModelId,
      cihazTipDurum: selectedDurum,
      search: searchTerm,
    });
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black-color bg-opacity-50">
      <div className="bg-white-color p-8 rounded-lg w-2/4 h-5/6 border-2 border-primary-brand-color overflow-auto">
        <div className="flex flex-row justify-between items-center mb-3">
          <h2 className="text-2xl font-semibold">Cihaz Filtrele</h2>
          <button
            className=" text-xl text-primary-brand-color"
            onClick={onClose}
          >
            <IoMdClose />
          </button>
        </div>
        <hr />

        <div className="my-4">
          <label className="block text-sm font-semibold mb-1" htmlFor="İlce">
            Cihaz Durum
          </label>
          <DurumSelect
            options={aktifDurumData}
            onChange={handleCihazDurumChange}
            defaultValue={selectedDurum}
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-semibold mb-1" htmlFor="Şehir">
            Cari
          </label>
          <CariSelect
            options={cariList}
            onChange={handleCariChange}
            addOperation={true}
            defaultValue={selectedCariId}
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-semibold mb-1" htmlFor="Sube">
            Şube
          </label>
          <SubeSelect options={subelerList} onChange={handleSubeChange} />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-semibold mb-1" htmlFor="Şehir">
            Cihaz Tip
          </label>
          <CihazTipSelect
            options={cihazTipList}
            onChange={handleCihazTipChange}
            addOperation={true}
            defaultValue={selectedCihazId}
            filterOperation={true}
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-semibold mb-1" htmlFor="Marka">
            Marka
          </label>
          <MarkaSelect
            options={markaList}
            onChange={handleMarkaChange}
            addOperation={false}
            defaultValue={selectedMarkaId}
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-semibold mb-1" htmlFor="Model">
            Model
          </label>
          <ModelSelect
            options={modelList}
            onChange={handleModelChange}
            addOperation={true}
            defaultValue={selectedModelId}
          />
        </div>

        <div className="flex w-full justify-end">
          <button
            type="button"
            className={`bg-primary-brand-color text-white-color px-4 py-2 rounded-md`}
            onClick={handleFilter}
          >
            Filtrele
          </button>
        </div>
      </div>
    </div>
  );
}

export default PopupFilterCihaz;
