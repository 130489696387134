import React from "react";
import { RiAddLine } from "react-icons/ri";

const AddButton = ({
  onPress,
  bgColor,
  title,
  iconColor,
  textColor,
  disabled = false,
}) => {
  return (
    <button
      onClick={onPress}
      className={`${
        disabled
          ? " bg-primary-brand-color bg-opacity-70 text-white-color cursor-not-allowed"
          : `bg-${bgColor} text-${textColor}`
      } px-4 py-2 rounded-md flex items-center justify-center my-2`}
      disabled={disabled}
    >
      <span className={`text-${iconColor} mr-2`}>
        <RiAddLine />
      </span>
      {title}
    </button>
  );
};

export default AddButton;
