import React, { useEffect, useState } from "react";
import { getIlceler } from "services/api/yonetimApi/api";
import Select from "react-select";

function IlceSelect({
  options,
  onChange,
  defaultValue,
  addOperation,
  selectedSehirId,
}) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [ilcelerList, setIlcelerList] = useState([]);

  useEffect(() => {
    if (selectedSehirId) {
      setSelectedOption(null);
      getIlceProcess(selectedSehirId).then((ilceler) => {
        setIlcelerList(ilceler);
      });
    } else {
      console.log("Add Yeri");
      setSelectedOption(null);
      setIlcelerList(options);
    }
  }, [selectedSehirId, defaultValue, options]);

  useEffect(() => {
    if (defaultValue && ilcelerList.length > 0) {
      if (addOperation == true) {
        const defaultIlce = ilcelerList.find(
          (ilce) => ilce.IlceId === defaultValue
        );
        console.log(defaultIlce);
        if (defaultIlce != undefined) {
          setSelectedOption({
            value: defaultIlce.IlceId,
            label: defaultIlce.IlceAdi,
          });
        }
      } else {
        const defaultIlce = ilcelerList.find(
          (ilce) => ilce.IlceId === defaultValue.IlceId
        );
        console.log(defaultIlce);
        if (defaultIlce != undefined) {
          setSelectedOption({
            value: defaultIlce.IlceId,
            label: defaultIlce.IlceAdi,
          });
        }
      }
    }
  }, [defaultValue, ilcelerList]);

  const transformToOption = (data) => {
    return data.map((item) => ({
      value: item.IlceId,
      label: item.IlceAdi,
    }));
  };

  const ilceOptions = transformToOption(options);
  const ilcelerListOpitons = transformToOption(ilcelerList);

  const getIlceProcess = async (sehirId) => {
    try {
      const response = await getIlceler(sehirId);
      return response.data;
    } catch (error) {
      console.error("Error fetching ilceler: ", error);
      return [];
    }
  };

  const handleIlceChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onChange(selectedOption);
  };

  return (
    <div className="flex flex-row w-full justify-center items-center">
      <Select
        className="basic-single w-full"
        classNamePrefix="select"
        onChange={handleIlceChange}
        options={addOperation ? ilceOptions : ilcelerListOpitons}
        placeholder={addOperation ? "İlçe Seçinlen..." : "İlçe Seçin..."}
        value={selectedOption}
      />
    </div>
  );
}

export default IlceSelect;
