import React, { useEffect, useState } from "react";
import Select from "react-select";
import { IconButton } from "../generalComponents";
import { RiAddLine } from "react-icons/ri";

function MarkaSelect({
  options,
  onChange,
  defaultValue,
  addOperation,
  disabled = false,
  filterOperation = true,
  onClickButton,
}) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isDefaultValue, setIsDefaultValue] = useState(false);

  const handleMarkaChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onChange(selectedOption);
  };

  useEffect(() => {
    console.log("marka select default value: ", defaultValue);
  }, [defaultValue]);

  const markaOptions = options.map((marka) => ({
    label: marka.MarkaAdi,
    value: marka.MarkaId,
  }));

  if (defaultValue != undefined) {
    if (addOperation == true) {
      defaultValue = markaOptions.find((x) => x.value == defaultValue);
    } else {
      defaultValue = markaOptions.find((x) => x.value == defaultValue.MarkaId);
    }
  }

  return (
    <>
      {addOperation == false ? (
        <div className="flex flex-row w-full justify-center items-center">
          <Select
            className="basic-single w-full"
            classNamePrefix="select"
            onChange={handleMarkaChange}
            options={markaOptions}
            placeholder="Marka..."
            defaultValue={defaultValue != undefined && defaultValue}
            isDisabled={disabled}
          />
        </div>
      ) : (
        <div className="flex flex-row w-full justify-center items-center">
          <Select
            className="basic-single w-full"
            classNamePrefix="select"
            onChange={handleMarkaChange}
            options={markaOptions}
            placeholder="Marka..."
            value={defaultValue != undefined ? defaultValue : selectedOption}
          />
          <IconButton
            Icon={RiAddLine}
            bgColor={"primary-brand-color"}
            iconColor={"white-color"}
            onPress={onClickButton}
          />
        </div>
      )}
    </>
  );
}

export default MarkaSelect;
