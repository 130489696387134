import React, { useEffect, useState } from "react";
import Select from "react-select";

function IslemDurumSelect({ options, onChange, defaultValue, addOperation }) {
  const [selectedOption, setSelectedOption] = useState(defaultValue);

  // react-select'in onChange olayı
  const handleIslemDurumChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onChange(selectedOption); // Seçilen cari değeri üst bileşene iletmek için callback'i çağır
  };

  useEffect(() => {
    //console.log(options);
  }, [options, defaultValue]);

  if (defaultValue != undefined) {
    defaultValue = options.find((x) => x.value === defaultValue);
  }

  return (
    <>
      {addOperation == false ? (
        <div className="flex flex-row w-full justify-center items-center">
          <Select
            className="basic-single w-full"
            classNamePrefix="select"
            onChange={handleIslemDurumChange}
            options={options}
            placeholder="İşlem Durum Seçin..."
            defaultValue={defaultValue != undefined && defaultValue}
          />
        </div>
      ) : (
        <div className="flex flex-row w-full justify-center items-center">
          <Select
            className="basic-single w-full"
            classNamePrefix="select"
            onChange={handleIslemDurumChange}
            options={options}
            placeholder="İşlem..."
            value={defaultValue != undefined ? defaultValue : selectedOption}
          />
        </div>
      )}
    </>
  );
}

export default IslemDurumSelect;
