export const tuzelDurum = [
  { key: "1", value: "Özel" },
  { key: "2", value: "Tüzel" },
];

export const musteriTipData = [
  { label: "Müşteri", value: "Musteri" },
  { label: "Distribütör", value: "Distributor" },
  { label: "Tedarikçi", value: "Tedarikci" },
  { label: "Satıcı", value: "Satici" },
  { label: "Alt Bayi", value: "AltBayi" },
];

export const islemDurumData = [
  { label: "Servis Kabul", value: "ServisKabul" },
  { label: "Müşteri Talebi", value: "MusteriTalebi" },
  { label: "Parça Bekleniyor", value: "ParcaBekliyor" },
  { label: "İptal Edildi", value: "IptalEdildi" },
  { label: "Tamamlandı", value: "Tamamlandi" },
  // { label: "Dış Serviste", value: "DisServiste" },
  { label: "Onay Bekleniyor", value: "OnayBekliyor" },
  // { label: "Teslim Edildi", value: "TeslimEdildi" },
];

export const aktifDurumData = [
  { label: "Aktif", value: true },
  { label: "Pasif", value: false },
];

export const cariStatusData = [
  { label: "Özel Cari", value: "Ozel" },
  { label: "Tüzel Cari", value: "Tuzel" },
  { label: "Kamu Cari", value: "Kamu" },
];

export const kullaniciTipData = [
  { label: "Teknisyen", value: "Teknisyen" },
  { label: "Muhasebe", value: "Muhasebe" },
  { label: "Admin", value: "Admin" },
];
